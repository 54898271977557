.blog_cont {
    padding-top: 170px;

    .blog_top_header {

        display: flex;
        /* align-items: center; */
        justify-content: space-between;

        .top_header_heading {
            font-size: 36px;
            font-weight: 700;
            color: #2B2B2B;
            line-height: 48px;
        }
    }

    .search_bar_section {
        /* width: 60%; */
        background: none;

        .advance-btn {
            border: 1px solid #d1d5db;
            background: transparent;
            padding: 10px 12px;
            transition: all 0.3s ease-in-out;
            border-radius: 12px;

            svg {
                color: gray;
            }
        }

        .advance-btn:hover {
            background-color: #7a1ea3;

            svg {
                color: #fff;
            }
        }






    }

    .blog_big_image {
        margin: 40px 0;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .blog_heading {
        font-size: 32px;
        font-weight: 600;
        color: #2B2B2B;
        line-height: 50px;
        text-align: center;
        padding: 0px 24px;
    }

    .blog_para {
        font-size: 16px;
        text-align: center;
        padding: 12px 30px;
        color: #585981;
        line-height: 30px;
    }

    .blog_many_cards {

        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        margin: 24px 0;
        justify-content: space-between;
    }

    .blog_many_card {

        width: 48%;
        display: flex;
        align-items: center;
        padding: 0 0 30px 0;
        gap: 16px;
        flex-direction: column;

        .card_many_image {
            width: 100%;
            height: 220px;
            border-radius: 12px;
            overflow: hidden;
            transition: all 0.6s ease-in-out;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

           
        }
        .housing_tag {
            position: absolute;
            z-index: 1;
            bottom: 15px;
            left: 30%;
            background-color: #fff;
            padding: 6px 12px;
            border-radius: 6px;
            color: #2B2B2B;
            display: flex;
            font-size: 15px;
            font-weight: 600;
            gap: 8px;
            cursor: pointer;
            align-items: center;

        }

        .housing_tag svg {
            color: #585981;
        }

        .read_more_btn {
            display: flex;
            color: #7A1EA3;
            align-items: center;
            font-size: 15px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
        }

        .read_more_btn svg {
            margin-left: 6px;
        }

        .read_more_btn:hover svg {
            transition: all 0.3s ease-in-out;
            transform: translateX(8px);
        }
    }

    .blog_many_card:hover {
        img {
            transition: all 0.6s ease-in-out;
            transform: scale(1.16);
        }
    }














    .blog_right {
        .search_box {
            padding: 40px 32px;
            background-color: #f5f3f3;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            gap: 10px;
            border: 1px solid #7A1EA3;
        }

        .input-text-box {
            width: 100% !important;
            position: relative;
        }

        input {
            background: none;
            font-family: "Poppins", sans-serif !important;
            padding: 11px 18px 11px 28px !important;
            font-size: 14px;
            width: 100%;
            border-radius: 10px !important;
        }

        .search-for-input {
            position: absolute;
            top: 24%;
            left: 8px;

            svg {
                color: #6b7280;
            }
        }

        .category_box {
            position: relative;
            /* Important for positioning the icon */
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0 0px 0;
            color: #2B2B2B;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
        }

        .category_box svg {

            transition: transform 0.3s ease;
            position: relative;
            margin-right: 10px;
        }

        .category_box:hover svg {
            transform: translateX(10px);
            color: #7A1EA3;
        }

        .category_box:hover {
            color: #7A1EA3;
        }

        .features_card_blog {
            display: flex;
            align-items: center;
            transition: all 0.3s ease-in-out;
            overflow: hidden;
            gap: 20px;
            padding: 12px 0;
            color: #2B2B2B;
        }

        .features_card_image {
            width: 140px;
            height: 90px;
            border-radius: 12px;
            transition: all 0.3s ease-in-out;

            overflow: hidden;

            img {
                width: 100%;
                height: 100%;

            }
        }

        .features_card_blog:hover img {
            transform: scale(1.15);
            transition: all 0.5s ease-in-out;
        }

        .text_features {
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s ease-in-out
        }

        .text_features:hover {
            color: #7A1EA3;
        }

        .price_feature {
            font-size: 18px;
            font-weight: 600;

        }

        .near_cards {

            display: flex;
            gap: 12px;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 20px 0;
        }

        .near_card {

            width: 48%;
            overflow: hidden;
            position: relative;
            max-height: 120px;
            border-radius: 12px;

            color: #fff;

            .near_image img {
                width: 100%;
                overflow: hidden;
                border-radius: 12px;
                height: 120px;
                transition: all 0.5s ease-in-out;
            }

            .content {
                position: absolute;
                bottom: 10px;
                left: 10px;

                .heading_near {
                    font-size: 18px;
                    font-weight: 600;
                }

                .much_property {
                    font-size: 14px;
                }
            }
        }

        .near_card:hover img {

            transform: scale(1.2);

        }

        .gorgeous_apartment_card {
            border-radius: 16px;
            height: 500px;
            margin: 50px 0;
            position: relative;
            color: #fff;
            overflow: hidden;

            img {
                width: 100%;
                height: 499px;
            }

            .cont {
                position: absolute;
                bottom: 30px;
                left: 25px;
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }

        .tags_btns {
            display: flex;
            gap: 15px;
            flex-wrap: wrap;
            margin: 24px 0;
            padding-right: 20px;

        }

        .tag_btn {
            border: 1px solid gray;
            border-radius: 12px;
            font-size: 14px;
            transition: all 0.3s ease-in-out;
            font-weight: 500;
            background: transparent;
            padding: 6px 16px;
            color: #2B2B2B;
        }

        .tag_btn:hover {
            color: #fff;
            background: #7A1EA3;
            border-color: #7A1EA3;
        }

    }



    .find_home_section {
        background: url('https://themesflat.co/html/dreamhomehtml/assets/images/section/bg-contact2.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 140px;
        margin-bottom: 80px;

        .left_agent_cont {
            padding: 70px 0;

            .para {
                font-size: 18px;
                font-weight: 400;
                color: #2B2B2B;
                line-height: 24px;
            }

            .primary-btn {
                width: fit-content;
                display: flex;
                gap: 8px;
                align-items: center;

            }

        }

        .big_heading {
            font-size: 54px;
            color: #2B2B2B;
            line-height: 60px;
            font-weight: 700;
        }

       

        .right_agent_img {
            display: flex;
            justify-content: end;
            align-items: end;
            margin-top: -250px;
            bottom: 0;
        }
    }

    .animated_card {
        border-radius: 12px;
        background-color: #dbcee0;
        margin-bottom: 100px;
        display: flex;
        gap: 24px;
        position: relative;
        padding: 40px;

        .right_cont_card {
            h3 {
                font-weight: 700;
            }
        }

        img {
            transition: transform 0.6s ease-in-out;
        }

        .primary-btn {
            position: absolute;
            width: fit-content;
            bottom: -30px;
        }
    }

    .animated_card:hover {

        img {
            transform: rotateY(180deg);

        }
    }
}



@media (max-width:576px) {
    .blog_big_image {
        margin: 20px 0 !important;
    }

    .search_bar_section {
        width: auto !important;
    }

    .blog_cont {
        padding-top: 70px !important;
    }

    .blog_heading {
        font-size: 18px !important;
        line-height: 30px !important;
        padding: 0px !important;
    }
    .blog_para {
        font-size: 14px !important;
        text-align: center;
        padding: 12px 0px !important;
        color: #585981;
        line-height: 30px;
    }
    .blog_many_card{
        width: 100% !important;
        .housing_tag{
            left: 20% !important;
        }
    }

    .features_card_image{
        width: 185px !important;
    }
    .text_features{
        font-size: 14px !important;
    }

    .near_card {
        width: 45% !important;
        .heading_near{
            font-size: 16px !important;
        }
    }

    .gorgeous_apartment_card{
        height: 400px !important;
        img{
            height: 400px !important;
        }
    }
    .tags_btns{
        padding-right: 0px !important;
    }
    .find_home_section {

        margin-top: 80px !important;
        margin-bottom: 80px;

        .left_agent_cont {
            padding: 70px 16px !important;

            .para {
                font-size: 16px !important;
            }



        }

        .big_heading {
            font-size: 36px !important;
            line-height: 50px !important;
        }

        .right_agent_img {

            margin-top: 0px !important;

        }
    }

    .animated_card {

        flex-wrap: wrap !important;
        gap: 16px !important;
        padding: 24px 24px 40px 24px !important;
    }

    .primary-btn {
        left: 20%;
    }
}


@media (min-width:577px) and (max-width:976px){
    .search_bar_section{
        width: auto !important;
    }
    .blog_many_card{
width: 45% !important;
.housing_tag{
    left: 5% !important;
}
    }

    .features_card_image{
width: 180px !important;
    }
   
    .text_features{
        font-size: 14px !important;
    }
    .near_card {
        width: 100% !important;
    }

    .tags_btns{
padding-right: 0px !important;
    }
    .tag_btn{
padding: 5px 12px !important;
font-size: 14px !important;
    }
}