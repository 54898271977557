
.Content_login {
    font-family: 'Poppins', sans-serif !important;

    color: #2B2B2B;
    padding-top: 170px;
    padding-bottom: 100px;
    background: url('/public/Images/login-back-image.png');
    background-size: cover;
    background-position: default;
    background-repeat: no-repeat;
    width: 100%;
    .p-inputtext {
        padding-left: 12px !important;
       
    }
    .p-dropdown{
        .p-inputtext {
           padding-top: 12px;
           
        }
    }
    .forgot-btn{
        cursor: pointer;
        color: #7a1ea3;
        font-weight: 500;
    }
    .mid_card {
        width: 500px;

        border-radius: 10px;
        margin: 0 auto;
    }

    .top_both_btn {
        display: flex;
        justify-content: space-between;
        align-items: end;
        gap: 10px;
.active{
    box-shadow: none !important;
    height: 55px;
}
        button {
            width: 100%;
            height: 45px;
            font-size: 16px;
            font-weight: 500;
          
            color: #2B2B2B;
            padding: 12px 16px;
            background-color: #FAFAFA;
            border: none;
            box-shadow: rgba(180, 177, 177, 0.35) 0px -50px 20px -28px inset;
            border-radius: 10px 10px 0 0;
            transition: all .3s ease;
           
        }
        button:hover{
          height: 55px;
        }
    }

    .down_white_card {
        background-color: white;
        border-radius: 0 0 10px 10px;
        padding: 60px;

        .continue_with_social_btn {
            background: #fff;
            display: flex;
            border-radius: 8px;
            align-items: center;
            gap: 16px;
            box-shadow: 3px 3px 20px -10px #968f8f;

            padding: 14px 24px;

            cursor: pointer;
            transition: all .6s ease;
            margin-bottom: 16px;

            svg {
                height: 26px;
                width: 30px;
            }

            img {
                width: 30px;
            }
        }



    }

    .horizontal_line {
        width: 100%;
        height: 1px;
        background-color: #a79c9c;
    }

}

@media (max-width:576px){
    .Content_login{
        background: none !important;
        /* min-height: 800px; */
        height: auto;
        color: #2B2B2B;
        padding-top: 70px;
        padding-bottom: 50px;
    
       
    }
    .mid_card {
        width: 98% !important;

        border-radius: 10px;
        margin: 0 auto;
    }

    .down_white_card {
        background-color: white;
        border-radius: 0 0 10px 10px;
        padding: 20px 16px !important;

        .continue_with_social_btn {


            padding: 14px 12px !important;
        
        }
        .primary-btn{
            width: 100%;
            font-size: 16px !important;
            padding: 4px 20px;
        }



    }
    
}

@media (min-width:577px) and (max-width:976px){
    .Content_login{
        height: auto;
        color: #2B2B2B;
        padding-top: 70px;
        padding-bottom: 50px;
    
       
    }
    .mid_card {
        width: 500px !important;

        border-radius: 10px;
        margin: 0 auto;
    }

    .down_white_card {
        background-color: white;
        border-radius: 0 0 10px 10px;
        padding: 25px 24px !important;

        .continue_with_social_btn {


            padding: 14px 18px !important;
        
        }
      


    }
    
}

@media (min-width: 1600px) {
    .Content_login {
        max-width: 2100px;
        margin: auto;
    }
}


.p-inputgroup-addon {
    padding: 12px !important;
}

.p-inputtext:enabled:hover {
    border-color: #d1d5db !important;
}

.p-dropdown:not(.p-disabled).p-focus{
    box-shadow: none;
}
.p-dropdown:not(.p-disabled):hover {
    border-color: #d1d5db;
}
.p-inputtext:enabled:focus {
    outline: 0;
    outline-offset: 0;
    padding-left: 12px;
    box-shadow: none !important;
    border-color: #d1d5db !important;
}

.p-input-icon-right > svg{
    top: 35% !important;
}