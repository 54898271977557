.agents_body_cont {
    padding-top: 150px;

    .agents_top_header {

        display: flex;
        /* align-items: center; */
        justify-content: space-between;

        .top_header_heading {
            font-size: 36px;
            font-weight: 700;
            color: #2B2B2B;
            line-height: 48px;
        }
    }

    .search_bar_section {
        width: 60%;
        background: none;

        .advance-btn {
            border: 1px solid #d1d5db;
            background: transparent;
            padding: 10px 12px;
            transition: all 0.3s ease-in-out;
            border-radius: 12px;

            svg {
                color: gray;
            }
        }

        .advance-btn:hover {
            background-color: #7a1ea3;

            svg {
                color: #fff;
            }
        }

        .input-text-box {
            width: 40%;
            position: relative;
        }

        .dropdown-box {
            width: 40%;
        }

        .search-for-input {
            position: absolute;
            top: 24%;
            left: 8px;

            svg {
                color: #6b7280;
            }
        }

        input {
            background: none;
            font-family: "Poppins", sans-serif !important;
            padding: 11px 18px 11px 28px !important;
            font-size: 14px;
            width: 100%;
            border-radius: 10px !important;
        }
    }

    .agents_cards {
        margin: 30px 0 50px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 30px;

        .agent_card {
            background: #fff;
            border-radius: 12px;
            width: 31.5%;
            margin-top: 12px;
            min-width: 320px;

            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

            .agent_image {
                border-radius: 12px;

                overflow: hidden;

                img {
                    width: 100%;

                    height: 216px;
                    transition: all 0.6s ease-in-out;
                }
            }

            .content_card {
                padding: 24px 16px 24px 16px;
            }

            .agent_name {
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                color: #2B2B2B;
            }

            .agent_about {
                font-size: 14px;
                color: #6b7280;
                line-height: 24px;

            }

            .agent_contact {
                font-size: 16px;
                color: #2B2B2B;
                cursor: pointer;
                display: flex;
                gap: 6px;
                align-items: center;
                line-height: 24px;
                font-weight: 500;

                svg {
                    color: #6b7280;
                }
            }

            .horizontal_line {
                width: 100%;
                height: 1px;
                background-color: #abafb4;
                margin: 18px 0;

            }

            .social_icons {
                display: flex;
                gap: 24px;
                color: #696a6b;

                svg {
                    cursor: pointer;
                }
            }
        }

        .agent_card:hover {
            img {
                transform: scale(1.2);
            }
        }
    }

    .pagination {
        margin: 40px 0 80px 0;
        display: flex;
        justify-content: center;
    }

    .find_home_section {
        background: url('https://themesflat.co/html/dreamhomehtml/assets/images/section/bg-contact2.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 140px;
        margin-bottom: 80px;

        .left_agent_cont {
            padding: 70px 0;

            .para {
                font-size: 18px;
                font-weight: 400;
                color: #2B2B2B;
                line-height: 24px;
            }

            .primary-btn {
                width: fit-content;
                display: flex;
                gap: 8px;
                align-items: center;

            }

        }

        .big_heading {
            font-size: 54px;
            color: #2B2B2B;
            line-height: 60px;
            font-weight: 700;
        }

        .right_agent_img {
            display: flex;
            justify-content: end;
            align-items: end;
            margin-top: -250px;
            bottom: 0;
        }
    }

    .animated_card {
        border-radius: 12px;
        background-color: #dbcee0;
        margin-bottom: 100px;
        display: flex;
        gap: 24px;
        position: relative;
        padding: 40px;
        .right_cont_card{
            h3{
                font-weight: 700;
            }
        }
        img{
            transition: transform 0.6s ease-in-out;
        }

        .primary-btn {
            position: absolute;
            width: fit-content;
            bottom: -30px;
        }
    }

    .animated_card:hover {
    
            img {
                transform: rotateY(180deg);
            
        }
    }
}








@media (max-width:576px){
    .agents_body_cont {
        padding-top: 60px;} 

        .agents_top_header {
            flex-wrap: wrap !important;
            gap: 16px;
            justify-content: center !important;
    
            .top_header_heading {
                font-size: 28px !important;
              
            }
        }
        .search_bar_section {
            width: 100% !important;
    
            .input-text-box {
                width: 60% !important;
                position: relative;
            }
    
            .dropdown-box {
                width: 100% !important;
            }
        }

        .agents_cards {
         
            justify-content: center !important;
          
    
            .agent_card {
             
                width: 100% !important;
            
                min-width: 280px !important;
    
              
    
            }
    
        }
        .find_home_section {
         
            margin-top: 80px !important;
            margin-bottom: 80px;
    
            .left_agent_cont {
                padding: 70px 16px !important;
    
                .para {
                    font-size: 16px !important;
                }
    
              
    
            }
    
            .big_heading {
                font-size: 36px !important;
              line-height: 50px !important;
            }
    
            .right_agent_img {
               
                margin-top: 0px !important;
               
            }
        }
        .animated_card {
         
            flex-wrap: wrap !important;
            gap: 16px !important;
     padding: 24px 24px 40px 24px !important ;
        }
        .primary-btn{
            left: 20%;
        }
}
  

@media (min-width:577px) and (max-width:976px){
    .agents_body_cont {
        padding-top: 80px;} 

        .agents_top_header {
            flex-wrap: wrap !important;
            gap: 16px;
            justify-content: center !important;
    
            .top_header_heading {
                font-size: 28px !important;
              
            }
        }
        .search_bar_section {
            width: 80% !important;
    
            .input-text-box {
                width: 30% !important;
                position: relative;
            }
    
            .dropdown-box {
                width: 40% !important;
            }
        }  
        .find_home_section {
         
            margin-top: 80px !important;
            margin-bottom: 80px;
    
            .left_agent_cont {
                padding: 70px 16px !important;
    
                .para {
                    font-size: 16px !important;
                }
    
              
    
            }
    
            .big_heading {
                font-size: 36px !important;
              line-height: 50px !important;
            }
    
            .right_agent_img {
               
                margin-top: 0px !important;
               
            }
        }

        .animated_card {
         
            flex-wrap: wrap !important;
            gap: 16px !important;
     padding: 24px 36px 40px 36px !important ;
        }
        .primary-btn{
            left: 20%;
        }
}

@media (min-width:1600px) {
    .find_home_section {
        max-width: 2100px;
        margin-left: auto;
        margin-right: auto;
    }
}


.p-dropdown .p-dropdown-label.p-placeholder {
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
}

.p-inputtext:enabled:hover {
    border-color: #d1d5db !important;
}

.p-dropdown {
    font-family: "Poppins", sans-serif !important;
    padding: 11px 16px;
    font-size: 15px;
    border-radius: 10px !important;
}

.p-dropdown .p-dropdown-label {
    font-size: 15px !important;
}

.p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #d1d5db;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: #d1d5db;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #d1d5db;
}

.p-dropdown-panel {
    .p-connected-overlay-enter-done {
        border: 1px solid red !important;
        padding: 10px;
    }
}


/* Assuming .p-dropdown-items-wrapper is the correct class for the dropdown items wrapper */

.p-dropdown-items-wrapper {
    scrollbar-width: thin;
    scrollbar-color: rgb(172, 169, 169) rgba(161, 156, 156, 0.363);
    scroll-behavior: smooth;

    /* & ::-webkit-scrollbar-track {
    background-color: red;
    border-radius: 10px;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: rgb(161, 156, 156);
    border-radius: 10px;
  } */
}

.p-inputtext:enabled:hover {
    border-color: #7a1ea3 !important;
}

.p-dropdown-items {
    padding: 0 !important;

    li {
        padding: 8px 12px;
        transition: all 0.2s ease-in-out;
    }

    li:hover {
        transition: all 0.2s ease-in-out;
        background-color: #7a1ea3;
        color: #fff;
    }
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible) .p-checkbox-box {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #d1d5db !important;
    background: #7a1ea3 !important;
}

.p-dropdown .p-dropdown-trigger {
    color: #7a1ea3 !important;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
    border-color: #d1d5db !important;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #d1d5db;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #4b5563;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    outline-color: transparent;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
    border-color: #7a1ea3 !important;
    background: #7a1ea3 !important;
    color: #ffffff;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input).p-highlight .p-checkbox-box {
    border-color: #7a1ea3 !important;
    background: #7a1ea3 !important;
    color: #ffffff;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
    background-color: #ccb0d8af;
    color: #7a1ea3;
}