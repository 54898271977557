.main-search-container {
  position: absolute;
  top: 50px;
  overflow: hidden;
  font-family: "Poppins", sans-serif !important;
  width: 100%;
  z-index: 4;
 

  /* for first search card */
.another-card-height{
  height: 255px !important;
}
.height0{
  height: 0px;
}
  .search-container {
    background-color: #fff;
    border-radius: 12px;
    width: 100%;
    padding: 0 !important;
    box-shadow: 0px 4px 26px 0px rgba(66, 71, 76, 0.08);
    overflow: hidden;
    font-family: "Poppins", sans-serif !important;
    .nav-tabs {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 !important;
      overflow: hidden;
      gap: 0;
    
      font-family: "Poppins", sans-serif !important;
    }

    .nav-tabs .nav-link {
      border: none;
      color: #2b2b2b;
      border-radius: 0px;
      font-size: 16px;
      transition: all 0.3s ease-in-out;
      background-color: #fff;
      font-weight: 600;
      line-height: 150%;
      padding: 8px auto;
      width: 100%;
      text-align: center;
      margin-bottom: 0 !important;
    }
    .nav-item {
      /* min-width: 16%; */
      flex: 1;
      background-color: #fff;
      margin: 0 !important;
      padding: 0 !important;
      border: none;
    }
  
    .nav-tabs .nav-link.active {
      color: #fff;
      margin: 0 !important;
      background-color: #7a1ea3;
    }
    .search-bar {
      width: 100%;
      background: none;
      padding: 22px 22px 18px 22px;
      .input-text-box {
        width: 33%;
        position: relative;
      }
      .dropdown-box {
        width: 18%;
      }
    
      .search-for-input{
        position: absolute;
      top: 24%;
      left: 8px;
        svg{
         color: #6b7280;
        }
      }

      input {
        background: none;
        font-family: "Poppins", sans-serif !important;
        padding: 11px 18px 11px 28px;
        font-size: 14px;
        width: 100%;
        border-radius: 10px !important;
      }
    }
    .extand-btn {
      width: 45px;
      height: 45px;
      font-size: 12px;
      border: none;
      border-radius: 10px;
      color: #7a1ea3;
      transition: all 0.3s ease-in-out;
      background-color: #cfb4db5e;
      svg {
        color: #7a1ea3;
      }
    }
    .extand-btn:hover {
      background-color: #7a1ea3;
      transition: all 0.3s ease-in-out;
      svg {
        color: #fff;
      }
    }
    .voice-btn {
      width: 45px;
      height: 45px;
      font-size: 12px;
      border: none;
      border-radius: 10px;
      color: #7a1ea3;
      transition: all 0.3s ease-in-out;
      background-color: #cfb4db5e;
      svg {
        color: #7a1ea3;
      }
    }
    .voice-btn:hover {
      background-color: #7a1ea3;
      transition: all 0.3s ease-in-out;
      svg {
        color: #fff;
      }
    }

    .submit-search {
      background: #7a1ea3;
      border: none;
      color: #fff;
      padding: 12px 16px;
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      border-radius: 10px;
      transition: all 0.3s ease-in-out;
      svg {
        color: #fff;
        
      }
    }
    .submit-search:hover {
      background-color: #2B2B2B;
      transition: all 0.3s ease-in-out;
      color: #fff;
      svg {
        color: #fff;
      }
    }
  }

  /*  for second search card  */
  .another-search-container {
    background-color: #fff;
    border-radius: 12px;
    width: 100%;
    color: #2b2b2b;
    margin-top: 25px;
    box-shadow: 0px 3px 10px  rgb(0 0 0 / 0.2);
    overflow: hidden;
    font-family: "Poppins", sans-serif !important;
    .state-drop-box {
      width: 18.4%;
    }
    .amount-rage-box {
      width: 23.4%;
      .label-range {
        font-size: 15px;
        font-weight: 600;
      }
    }
    .p-slider-handle-end,
    .p-slider-handle-start {
      top: -6px !important;
      cursor: pointer;
    }
    .size-rage-box {
      width: 23.4%;
    }
    #horizontal-line {
      width: 100% !important;
      height: 1px !important;
      margin: 15px 0;
      background-color: #d1d5db;
    }
  }

  /* for prime react dropdown and input box css */
  .p-dropdown .p-dropdown-label.p-placeholder {
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
  }
  .p-inputtext:enabled:hover {
    border-color: #d1d5db !important;
  }
  .p-dropdown {
    font-family: "Poppins", sans-serif !important;
    padding: 11px 16px;
    font-size: 15px;
    border-radius: 10px !important;
  }
  .p-dropdown .p-dropdown-label {
    font-size: 15px !important;
  }
 
  .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #d1d5db;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: #d1d5db;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: #d1d5db;
  }

  .p-dropdown-panel {
    .p-connected-overlay-enter-done {
      border: 1px solid red !important;
      padding: 10px;
    }
  }
}
/*  for second card prime react slider and dropdown under searchinput and other css */
.p-slider-range {
  background: #7a1ea3 !important;
}
.p-slider-handle {
  border: 2px solid #fff !important;
  background: #7a1ea3 !important;
}
.p-slider-handle:focus {
  box-shadow: none;
}
/* Assuming .p-dropdown-items-wrapper is the correct class for the dropdown items wrapper */

.p-dropdown-items-wrapper {
  scrollbar-width: thin;
  scrollbar-color: rgb(172, 169, 169) rgba(161, 156, 156, 0.363);
  scroll-behavior: smooth;

  /* & ::-webkit-scrollbar-track {
    background-color: red;
    border-radius: 10px;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: rgb(161, 156, 156);
    border-radius: 10px;
  } */
}

.p-dropdown-filter-container {
  margin: 6px 4px 8px 4px !important;

  .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #d1d5db;
  }
}
.p-inputtext:enabled:hover {
  border-color: #7a1ea3 !important;
}
.p-dropdown-items {
  padding: 0 !important;
  li {
    padding: 8px 12px;
    transition: all 0.2s ease-in-out;
  }
  li:hover {
    transition: all 0.2s ease-in-out;
    background-color: #7a1ea3;
    color: #fff;
  }
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible)
  .p-checkbox-box {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #d1d5db !important;
  background: #7a1ea3 !important;
}
.p-dropdown .p-dropdown-trigger{
  color: #7a1ea3 !important;
}
.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
  border-color: #d1d5db !important;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #d1d5db;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #4b5563;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  outline-color: transparent;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight
  .p-checkbox-box {
  border-color: #7a1ea3 !important;
  background: #7a1ea3 !important;
  color: #ffffff;
}
.p-checkbox:not(.p-disabled):has(.p-checkbox-input).p-highlight
  .p-checkbox-box {
  border-color: #7a1ea3 !important;
  background: #7a1ea3 !important;
  color: #ffffff;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus{
  background-color:#ccb0d8af;
  color: #7a1ea3;
}



@media (max-width:480px){
  
  .main-search-container {
    top: 70px;
   .nav-tabs {
    display: none !important;
   }
   .another-card-height{
    height: 845px !important;
  }
  .state-drop-box{
    width: 100% !important;
  }
  .amount-rage-box {
    width: 100% !important; 
    .label-range {
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }
   .search-bar {
    width: 100%;
    background: none;
    padding: 16px 16px 16px 16px !important;
  }
   .input-text-box {
    width: 100% !important;
    position: relative;
  }
  .dropdown-box {
    width: 100% !important;
  }

  }
}
@media (min-width:481px) and (max-width:767px){
  
  .main-search-container {
    top: 70px;
   .nav-tabs {
    display: none !important;
   }
   
  .state-drop-box{
    width: 47% !important;
  }
  .amount-rage-box {
    width: 48% !important; 
    .label-range {
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }
   .search-bar {
    width: 100%;
    background: none;
    padding: 16px 16px 16px 16px !important;
  }
   .input-text-box {
    width: 100% !important;
    position: relative;
  }
  .dropdown-box {
    width: 48% !important;
  }

  }
}
/* for only another search card */
@media (min-width:481px) and (max-width:575px){
  .main-search-container {
    .another-card-height{
      height: 655px !important;
      
    }
  }

}
/* for only another search card */
@media (min-width:576px) and (max-width:767px){
  .main-search-container {
    .another-card-height{
      height: 480px !important;
    
    }
  }
  
}
@media (min-width:767px) and (max-width:1012px){
  .main-search-container {
    .another-card-height{
      height: 480px !important;
    
    }
  }
  
}


@media (min-width:768px) and (max-width:1012px){
  
  .main-search-container {
    top: 70px;
   .nav-tabs {
    display: none !important;
   }
   
  .state-drop-box{
    width: 47% !important;
  }
  .amount-rage-box {
    width: 48% !important; 
    .label-range {
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }
   .search-bar {
    width: 100%;
    background: none;
    padding: 16px 16px 16px 16px !important;
  }
   .input-text-box {
    width: 100% !important;
    position: relative;
  }
  .dropdown-box {
    width: 48% !important;
  }

  }
}
@media (min-width:1013px) and (max-width:1200px){
  
  .main-search-container {
    top: 70px;
   .nav-tabs {
    display: none !important;
   }
   
  .state-drop-box{
    width: 18% !important;
  }
  .amount-rage-box {
    width: 24% !important; 
    .label-range {
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }
   .search-bar {
    width: 100%;
    background: none;
    padding: 20px 20px 20px 20px !important;
  }
   /* .input-text-box {
    width: 100% !important;
    position: relative;
  }
  .dropdown-box {
    width: 48% !important;
  } */

  }
}


@media (min-width:1500px){
  .input-text-box {
    width: 39% !important;
    position: relative;
  }
}