.list_property_cont {
  .top_list_section {
    background: url(/public/Images/list_property-top-bg.png);
    background-size: cover;
    background-position: default;
    padding: 180px 0 116px 0;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    object-fit: contain;

    .big-heading {
      font-size: 60px;
      font-weight: 700;
      margin-top: 80px;
      color: #ffffff;
      line-height: 120%;
    }


    .big-para {
      font-size: 20px;
      font-weight: 400;

      color: #fff;
      line-height: 150%;
      margin-top: 10px;
    }

    .list_property_form {
      background-color: #fff;
      padding: 24px;
      min-width: 520px;
      position: absolute;
      right: 0;
      border-radius: 12px;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      display: flex;
      flex-direction: column;
      gap: 42px;



    }

    .number_section_list {

      margin-top: 8px;

      .p-inputnumber-input {
        border-radius: 0px;
        padding-left: 8px;
        border-top: none;
        border-left: none;
        border-right: none;

      }

      .select_div {
        padding: 8px 0;
        border-bottom: 1px solid #cdc3c3;
        border-left: none;
        border-right: none;
        border-top: none;
      }
    }

    .whatsapp_description {
      display: flex;
      gap: 12px;
      align-items: center;
      background: #7A1EA31A;
      padding: 12px 16px;
      border: 1px solid #7A1EA3;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 450;
    }
  }

  .greeting_section {
    margin-top: 120px;
    margin-bottom: 100px;
  }

  .greeting_heading {
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    color: #2B2B2B;

  }

  .greeting_heading span {
    color: #7A1EA3;
  }

  .greeting_paragraph {
    font-size: 24px;
    font-weight: 400;
    color: #2B2B2B;

    line-height: 47px;
  }

  .greeting_cards {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
    margin-top: 100px;
    margin-bottom: 80px;

    .greeting_card {
      background: #fff;
      border-radius: 12px;
      width: 31.5%;
      color: #2B2B2B;
      transition: all 0.2s ease-in-out;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      padding: 24px 24px;

      p {
        padding: 0;
        margin: 0;
      }
    }

    .greeting_card:hover {
      background-color: #7A1EA3;
      color: #fff;
    }
  }

  .property_game_section {
    padding: 80px 0;
    background: url(/public/Images/game-property-bg.png);
  }

  .counting_section {
    background: url(/public/Images/count-big-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    margin-bottom: 80px;
    width: 100%;

    .count_card {

      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;

      .very_large_num {
        font-size: 112px;
        min-width: 137px;
        font-weight: 800;
        color: #00000033;
      }
    }
  }
}

@media (max-width:576px) {
  .top_list_section {
    background: url(/public/Images/list_property-top-bg.png);
    background-size: cover;
    background-position: default;
    padding: 0px 0 100px 0 !important;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    object-fit: contain;


    .list_property_form {
      background-color: #fff;
      width: 80% !important;

      padding: 24px 12px !important;
      min-width: 320px !important;
      margin-left: auto;
      margin-right: auto;
      left: 0px;
      position: absolute;

      top: 200px;
      border-radius: 12px;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      display: flex;
      flex-direction: column;
      gap: 18px !important;

      .primary-btn {
        width: 100%;
      }

    }

    .whatsapp_description {
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }

  .greeting_section {
    margin-top: 600px !important;
    margin-bottom: 100px;
  }

  .greeting_heading {
    font-size: 24px !important;
    line-height: 30px !important;

  }

  .greeting_paragraph {
    font-size: 18px !important;
  }

  .greeting_cards {
    gap: 20px !important;
    margin-top: 0px !important;

    .greeting_card {
      width: 100% !important;

    }

  }

  .property_game_section {
    padding: 40px 0 !important;
    background: url(/public/Images/game-property-bg.png);
  }

  .counting_section {

    background-position: right !important;

    margin-bottom: 0px !important;

    .count_card {

      margin: 10px 0 !important;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 10px !important;

      .very_large_num {
        font-size: 112px;
        min-width: 137px;
        font-weight: 800;
        color: #00000033;
      }
    }
  }
}

@media (min-width:577px) and (max-width:986px) {
  .top_list_section {
    background: url(/public/Images/list_property-top-bg.png);
    background-size: cover;
    background-position: default;
    padding: 50px 0 100px 0 !important;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    object-fit: contain;


    .list_property_form {
      background-color: #fff;

      padding: 24px 12px !important;
      min-width: 350px !important;
      margin-left: auto;
      margin-right: auto;

      position: absolute;
      right: 0;
      top: 200px;
      border-radius: 12px;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      display: flex;
      flex-direction: column;
      gap: 18px !important;



    }

    .whatsapp_description {
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }

  .greeting_section {
    margin-top: 450px !important;
    margin-bottom: 50px;
  }

  .greeting_heading {
    font-size: 24px !important;
    line-height: 30px !important;

  }

  .greeting_paragraph {
    font-size: 18px !important;
  }

  .greeting_cards {
    gap: 20px !important;
    margin-top: 40px !important;

    .greeting_card {
      width: 47% !important;

    }

  }

  .property_game_section {
    padding: 60px 0 !important;
    background: url(/public/Images/game-property-bg.png);
  }

  .counting_section {

    background-position: center !important;

    margin-bottom: 0px !important;

    .count_card {

      margin: 10px 0 !important;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 10px !important;

      .very_large_num {
        font-size: 112px;
        min-width: 137px;
        font-weight: 800;
        color: #00000033;
      }
    }
  }
}

@media (min-width:1600px) {
  .top_list_section {
    max-width: 2100px;
    margin: 0 auto;
  }

  .property_game_section {
    max-width: 2100px;

    margin: 0 auto;
  }

  .counting_section {
    max-width: 2100px;
    margin: 0 auto;
  }
}