.homepage {
  position: relative;
  font-family: "Poppins", sans-serif !important;

  .section-one {
    background: url(/public/Images/banner4.png);
    background-size: cover;
    background-position:default;
    padding: 174px 0 116px 0;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    object-fit: contain;
    color: #fff;
  }
  
.big-heading {
  font-size: 60px;
  font-weight: 700;
  color: #ffffff;
  line-height: 120%;
}


.big-para {
  font-size: 16px;
  font-weight: 400;
  width: 57%;
  color: #fff;
  line-height: 150%;
  margin-top: 20px;
}
.top-heading {
  h4 {
    color: #2b2b2b !important;
    margin-bottom: 34px;
    font-weight: 400 !important;
    span {
      font-weight: 300 !important;
      color: rgba(88, 89, 129, 1);;
      font-size: 22px ;
    }
  }
}
.for-down-container{
padding: 0px;
margin-bottom: 58px;
}
  .main-cont {
    display: flex;
    flex-direction: column;
    gap: 58px;
    padding: 100px 0 60px 0;
  
    .spotlight-heading {
      display: inline-block;
      height: 120px;
      width: 40%;

      position: absolute;
      background: #fafafa;
      z-index: 10;
      h4 {
        color: #2b2b2b !important;

        font-weight: 400 !important;
      }
      p {
        font-weight: 300 !important;
        font-size: 22px;
        color: rgba(88, 89, 129, 1);
      }
    }
 
  .download-top-heading{
    font-size: 40px;
    line-height: 45px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 35px;
    color: #2b2b2b;
    .para-download{
      font-size: 15px;
      font-weight: 400;
      color: #585981;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      line-height: 21px;
    }
  }
  
  .why-chose-us-cards-section {
    /* max-width: 1400px; */
    width: 100%;
    /* background-image: url(/public/Images/download-image.png); */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    height: max-content;
    margin: 0 auto;
    /* .choose-left-side{
    
      display: flex;
      gap: 0px; */
      .choose-card{
        width:30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 15px 20px;
 
      /* border-left: none !important;
      border-bottom: none !important;
      border-top: none !important; */
        .resion-heading{
          margin: 20px 0 16px 0;
          font-size: 16px;
          cursor: pointer;
          font-weight: 600;
         
          color: #2b2b2b;
        }

        .card-image-post{
          position: relative; 
          overflow: hidden; 
          border-radius: 12px;
          width: 100%;
        }
        .primary-btn{
         font-size: 16px;
        }
        .card-image-post img {
          width: 100%;
          height: 100%;
          transition: transform 0.5s; 
        }
        .card-image-post .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          background-color:rgba(0, 0, 0, 0.6); ;
          top: 0;
          left: 0;
          z-index: 2;
          opacity: 0;
          transition: transform 0.4s;
        }
        
       .post-logo-image{
    transition: all 0.3s ease-in-out;
        padding-top: 5px;
        height: 76px;
       }
        
       
        .resion-heading:hover{
          color: #7a1ea3;
          transition: all 0.3s ease-in-out;
        }
        .card-why-paragraph{
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          margin: 16px 0 20px 0;
          flex-grow: 1;
          color: #585981;
          text-align: center;
        }
        .see-all-listing-btn{
          position: relative;
          font-size: 14px;
          font-weight: 600;
          color: #2b2b2b;
          padding-right: 18px;
          cursor: pointer;
          display: inline;
          position: relative;
         
      
          svg {
            position: absolute;
            top: 10px;
            transform: translateY(-50%);
            right: -1px; /* Adjust as needed */
            transition: right 0.3s ease; /* Transition for smooth movement */
          }
        }
       
      }
      .see-all-listing-btn:hover {
        svg {
          right: -6px;
        }
      }
      .choose-card:hover{
        .post-logo-image{
          border: 1px solid rgb(44, 38, 38)(180, 21, 21);
          padding-top: 00px;
          transition: all 0.3s ease-in-out;
         }
          
      }
      .choose-card:hover .card-image-post img {
        transform: scale(1.11);
      }
      .choose-card:hover .card-image-post .overlay{
        transition: all 0.3s ease-in-out;
        opacity: 1;
      }
        
    
    /* .choose-right-side{
      width: 100%;
      height: 100%;
      background-image: url(/public/Images/handimage.png);
     overflow: hidden;
      background-size: cover;
    background-repeat: no-repeat;
    text-align: end;
    } */
  }
}
  .see-all-btn {
    color: #7a1ea3;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding-right: 18px;
    cursor: pointer;
    display: inline;
    position: relative;
    align-self: flex-start;

    svg {
      position: absolute;
      top: 10px;
      transform: translateY(-50%);
      right: -1px; /* Adjust as needed */
      transition: right 0.3s ease; /* Transition for smooth movement */
    }
  }
  .see-all-btn:hover {
    svg {
      right: -6px;
    }
  }
  /* .download-left-side {
    margin-top: 8rem;
  } */
  .download-container {
    
    width: 100%;
    background-image: url(/public/Images/download-image.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: max-content;
    margin: 0 auto 0px auto;
  }
  .download-app-images{
 
    img{
      width: 17%;
      cursor: pointer;
    }
  }
  .download-right-side{
    img{
      width: 100%;
    }
  }
  .download_text {
    font-size:30px;
    font-weight: 700;
    color: #2B2B2B;
    line-height: 10px;
  }

  .download_text_pra {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 22px;
    color: #2B2B2B;
    margin-top: 20px;

  }
  .download_text_small{
    font-size: 15px;
    padding: 0;
    margin-top: 10px;
    color: #585981;
    font-weight: 400;
    line-height: 25px;
    
  }
.right-para {
/* border: 1px solid red; */
font-size: 15px;
font-weight: 400;
line-height: 22px;

color: #2B2B2B;
.bold-text-right{
  font-weight: 600;
}

}


 
  .property-main_container {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .property_text_heading {
    font-size: 2rem;
    font-weight: 600;
    line-height: 47px;
    color: #2b2b2b;
  }

  .property_text_pra {
    color: #585981;
    font-size: 1rem;
    font-weight: 400;
    line-height: 26px;
    padding: 10px 0;
  }
  .agents-cards-section{
    width: 63% ;
  }
 .left-agents-text{
  /* border: 1px solid red; */
  width: 35% ;
  display: flex;
justify-content: center;
  flex-direction: column;
  gap: 6px;
  .agents-heading{
    color: #2B2B2B !important;
    
  }
  .agents-paragraph{
    color: #585981;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

 }
.footer-banner-normal{

  background: url('/public/Images/download-image2.png');
 max-height: 180px;
 min-height: 160px;
  display: flex;
  align-items: center;
  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;

 .container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .banner-man-image{
  

    img{
      
   margin-top: -48px;
  
    }
  }
  .banner-content{
    h2{
      color: #2b2b2b;
   
    }
    .banner-para{
      font-size: 15px;
      color: #585981;
      line-height: 25px;
      font-weight: 300;
  }
  }
 }
}  
}
@media (max-width: 480px) {
  .download-right-side{
    img{
      width: 80% !important;
    }
  }
  .download-app-images{
   display: flex;
   justify-content: space-between !important;
    img{
      width: 48% !important;
    }
  }
  .download_text {
    font-size:33px !important;
    font-weight: 650 !important;
    color: #2B2B2B;
    line-height: 35px !important;
    text-align: center;
  }
  .agents-cards-section{
    width: 100% !important;
  }
 .left-agents-text{
  /* border: 1px solid red; */
  width: 100% !important;
  display: flex;
justify-content: center !important;
align-items: center !important;
  flex-direction: column;
  gap: 0px;
  .agents-heading{
    color: #2B2B2B !important;
    font-size: 28px !important;
    font-weight: 400 !important;

  }
  .agents-paragraph{
    color: #585981;
    font-size: 16px;
    font-weight: 300 !important;
    line-height: 20px;
    text-align: center;
  }

 }
  .footer-banner-normal{
   max-height: 500px !important;
   /* display: block !important; */
   text-align: center;
   padding: 20px;
   .primary-btn{
    width: 75% !important;
    padding:  0px !important;
    font-size: 18px !important;
   }
   .container{
    display: inline!important;
text-align: center;
   
   }  
   .banner-man-image{
  

    img{
      
display: none !important;
  
    }
  }
  }
  .spotlight-heading{
    position:relative  !important;
    width: 100% !important;
    height: auto !important;
  }
 
 
  .section-one {
    background: url(/public/Images/banner.png);
    background-size: cover;
    background-position: center;
    padding: 60px 0 186px 0 !important;
    width: 100%;
    position: relative;
    object-fit: contain;
    color: #fff;
  }
  .big-heading {
    font-size: 30px !important;
    font-weight: 650 !important;
    color: #ffffff;
    line-height: 110%;
  }
  
  
  .big-para {
    font-size: 12px !important;
    font-weight: 450 !important;
    width: 90% !important;
    color: #fff;
    line-height: 130% !important;
   
    margin-top: 12px !important;
  }
  .main-cont {
    display: flex;
    flex-direction: column;
    gap: 38px;
    padding: 170px 30px 82px 30px !important;
  }

  .top-heading {
    h4 {
      color: #2b2b2b !important;
      margin-bottom: 14px !important;
      font-weight: 400 !important;
      text-align: center;
      span {
        font-weight: 300 !important;
        display: block;
        font-size: 18px !important;
        margin-top: 5px;
      }
    }
  }
  .see-all-btn {
    margin-bottom: 12px !important;

  }

  .property_text_heading {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 47px;
    color: #2b2b2b;
  }

  .property_text_pra {
    color: #585981;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    padding-top: 10px !important;
  }


  .download-top-heading{
    font-size: 32px !important;
    line-height: 1.2em;
    text-align: center;
    text-transform: capitalize;
    font-weight: 800;
    margin-bottom: 20px;
    color: #2b2b2b;
    .para-download{
      font-size: 14px;
      font-weight: 400;
      width: 98% !important;
      margin: 10px auto;
      margin-bottom: 0px;
      color: #585981;
      text-align: center;
      
      line-height: 21px;
    }
  }
  .choose-card{
    width:100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 15px 0px !important;
    
    
  }
}
@media (min-width: 481px) and (max-width:767px) {
  .download-right-side{
    text-align: center !important;
    img{
      width: 70% !important;
    }
  }
  .agents-cards-section{
    width: 100% !important;
  }
 .left-agents-text{
  /* border: 1px solid red; */
  width: 100% !important;}
  .spotlight-heading{
    position:relative  !important;
    width: 100% !important;
    height: auto !important;
  }
  .banner-man-image{
  

    img{
      
display: none !important;
  
    }
  }
  .download-top-heading{
    font-size: 32px !important;
    line-height: 1.2em;
    text-align: center;
    text-transform: capitalize;
    font-weight: 800;
    margin-bottom: 20px;
    color: #2b2b2b;
    .para-download{
      font-size: 14px;
      font-weight: 400;
      color: #585981;
      width: 85% !important;
      text-align: center;
      margin-top: 10px;
      line-height: 21px;
    }
  }
  .choose-card{
    width:48% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 15px 20px;
    /* border: 1px solid #c9c0c0; */
  border-left: none !important;
  border-bottom: none !important;
  border-top: none !important;
  border-left: nonr !important;
  border: none !important;
  border: 1px solid #dee2e600 !important;
    .resion-heading{
      margin: 20px 0 16px 0;
      font-size: 16px;
      cursor: pointer;
      font-weight: 600;
      color: #2b2b2b;
    }

    .card-image-post{
      position: relative; 
      overflow: hidden; 
      border-radius: 12px;
    }
    .card-image-post img {
      width: 100%;
      height: 100%;
      transition: transform 0.5s; 
    }
    .card-image-post .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color:rgba(0, 0, 0, 0.6); ;
      top: 0;
      left: 0;
      z-index: 2;
      opacity: 0;
      transition: transform 0.4s;
    }
    
   .post-logo-image{
transition: all 0.3s ease-in-out;
    padding-top: 5px;
    height: 76px;
   }
    
   
    .resion-heading:hover{
      color: #7a1ea3;
      transition: all 0.3s ease-in-out;
    }
    .card-why-paragraph{
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      margin: 16px 0 20px 0;
      color: #585981;
      text-align: center;
    }
    .see-all-listing-btn{
      position: relative;
      font-size: 13px;
      font-weight: 600;
      color: #2b2b2b;
      padding-right: 18px;
      cursor: pointer;
      display: inline;
      position: relative;
     
  
      svg {
        position: absolute;
        top: 10px;
        transform: translateY(-50%);
        right: -1px; /* Adjust as needed */
        transition: right 0.3s ease; /* Transition for smooth movement */
      }
    }
   
  }
  .section-one {
    background: url(/public/Images/banner.png);
    background-size: cover;
    background-position: center;
    padding: 80px 0 166px 0 !important;
    width: 100%;
    position: relative;
    object-fit: contain;
    color: #fff;
  }
  .big-heading {
    font-size: 42px !important;
    font-weight: 650 !important;
    color: #ffffff;
    line-height: 110% !important;
  }
  
  
  .big-para {
    font-size: 14px !important;
    font-weight: 450 !important;
    width: 90% !important;
    color: #fff;
    line-height: 130% !important;
    margin-top: 12px !important;
  }
  .main-cont {
    display: flex;
    flex-direction: column;
    gap: 42px !important;
    padding: 140px 30px 82px 30px !important;
  }

  .top-heading {
    margin-bottom: 12PX !important;
    h4 {
      color: #2b2b2b !important;
      margin-bottom: 6px !important;
      font-weight: 400 !important;
      span {
        font-weight: 300 !important;
      }
    }
  }
  .see-all-btn {
    margin-top: 4px !important;
    margin-bottom: 0px !important;
  
  }

  .property_text_heading {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 47px;
    color: #2b2b2b;
  }

  .property_text_pra {
    color: #585981;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    padding-top: 10px !important;
  }
}
@media (min-width: 768px) and (max-width:1012px) {
  .spotlight-heading{
    position:relative  !important;
    width: 100% !important;
    height: auto !important;
  }
  .banner-man-image{
  

    img{
      
display: none !important;
  
    }
  }
  .download-right-side{
    img{
      width: 70% !important;
    }
  }
  .download-top-heading{
    font-size: 38px !important;
    line-height: 1.2em;
    text-align: center;
    text-transform: capitalize;
    font-weight: 800;
    margin-bottom: 20px;
    color: #2b2b2b;
    .para-download{
      font-size: 14px;
      font-weight: 400;
      color: #585981;
      width: 90% !important;
      text-align: center;
      margin-top: 10px;
      line-height: 21px;
    }
  }
  .choose-card{
    width:48% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 15px 20px;
    /* border: 1px solid #c9c0c0; */
  border-left: none !important;
  border-bottom: none !important;
  border-top: none !important;
  border-left: nonr !important;
  border: none !important;
  border: 1px solid #dee2e600 !important;
    .resion-heading{
      margin: 20px 0 16px 0;
      font-size: 16px;
      cursor: pointer;
      font-weight: 600;
      color: #2b2b2b;
    }

    .card-image-post{
      position: relative; 
      overflow: hidden; 
      border-radius: 12px;
    }
    .card-image-post img {
      width: 100%;
      height: 100%;
      transition: transform 0.5s; 
    }
    .card-image-post .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color:rgba(0, 0, 0, 0.6); ;
      top: 0;
      left: 0;
      z-index: 2;
      opacity: 0;
      transition: transform 0.4s;
    }
    
   .post-logo-image{
transition: all 0.3s ease-in-out;
    padding-top: 5px;
    height: 76px;
   }
    
   
    .resion-heading:hover{
      color: #7a1ea3;
      transition: all 0.3s ease-in-out;
    }
    .card-why-paragraph{
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      margin: 16px 0 20px 0;
      color: #585981;
      text-align: center;
    }
    .see-all-listing-btn{
      position: relative;
      font-size: 13px;
      font-weight: 600;
      color: #2b2b2b;
      padding-right: 18px;
      cursor: pointer;
      display: inline;
      position: relative;
     
  
      svg {
        position: absolute;
        top: 10px;
        transform: translateY(-50%);
        right: -1px; /* Adjust as needed */
        transition: right 0.3s ease; /* Transition for smooth movement */
      }
    }
   
  }
  .section-one {
    background: url(/public/Images/banner.png);
    background-size: cover;
    background-position: center;
    padding: 90px 0 166px 0 !important;
    width: 100%;
    position: relative;
    object-fit: contain;
    color: #fff;
  }
  .big-heading {
    font-size: 55px !important;
    font-weight: 650 !important;
    color: #ffffff;
    line-height: 110% !important;
  }
  
  
  .big-para {
    font-size: 16px !important;
    font-weight: 450 !important;
    width: 80% !important;
    color: #fff;
    line-height: 130% !important;
    margin-top: 12px !important;
  }
  .main-cont {
    display: flex;
    flex-direction: column;
    gap: 42px !important;
    padding: 140px 30px 82px 30px !important;
  }

  .top-heading {
    margin-bottom: 12PX !important;
    h4 {
      color: #2b2b2b !important;
      margin-bottom: 6px !important;
      font-weight: 400 !important;
      span {
        font-weight: 300 !important;
      }
    }
  }
  .see-all-btn {
    margin-top: 4px !important;
    margin-bottom: 0px !important;
  
  }

  .property_text_heading {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 47px;
    color: #2b2b2b;
  }

  .property_text_pra {
    color: #585981;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    padding-top: 10px !important;
  }
}
@media (min-width: 1013px) and (max-width:1200px) {
  .spotlight-heading{
    position:relative  !important;
    width: 100% !important;
    height: auto !important;
  }
  .download-top-heading{
    display: none !important;
  }
  .why-choose-us-container{
    display: none !important;
  }
  .section-one {
    background: url(/public/Images/banner.png);
    background-size: cover;
    background-position: center;
    padding: 90px 0 120px 0 !important;
    width: 100%;
    position: relative;
    object-fit: contain;
    color: #fff;
  }
  .big-heading {
    font-size: 55px !important;
    font-weight: 650 !important;
    color: #ffffff;
    line-height: 110% !important;
  }
  
  
  .big-para {
    font-size: 16px !important;
    font-weight: 450 !important;
    width: 80% !important;
    color: #fff;
    line-height: 130% !important;
    margin-top: 12px !important;
  }
  .main-cont {
    display: flex;
    flex-direction: column;
    gap: 42px !important;
    padding: 90px 30px 82px 30px !important;
  }

  .top-heading {
    margin-bottom: 12PX !important;
    h4 {
      color: #2b2b2b !important;
      margin-bottom: 6px !important;
      font-weight: 400 !important;
      span {
        font-weight: 300 !important;
      }
    }
  }
  .see-all-btn {
    margin-top: 4px !important;
    margin-bottom: 0px !important;
  
  }

  .property_text_heading {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 47px;
    color: #2b2b2b;
  }

  .property_text_pra {
    color: #585981;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    padding-top: 10px !important;
  }
}

@media (min-width: 1600px) {
  .section-one {
    max-width: 2100px;
    margin: auto;
  }
  .footer-banner-normal {
    max-width: 2100px;
    margin: auto;
  }
  .download-container  {
    max-width: 2100px;
    margin: auto;
  }
  .spotlight-heading {
    display: inline-block;
    height: 120px;
    width: 600px !important;}
  /* .why-choose-us-container {
    max-width: 2100px;
    margin: auto;
  } */
}