.services_content_body {
   
    .big-heading {
        font-size: 36px;
        font-weight: 700;
        color: #2B2B2B;
        line-height: 130%;

        span {
            color: #7A1EA3;
        }
    }

    .big-para {
        font-size: 20px;
        font-weight: 400;
        color: #2B2B2B;
        line-height: 150%;
        margin-top: 10px;
    }

    .top_header_services {
        width: 70%;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .service_banner_section {
        background: url(../../../../public/Images/services-benner-image.png);
        background-position: left;
        background-repeat: no-repeat;
        padding: 250px 0 220px 0;
        background-size: cover;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        .big-heading {
            font-size: 60px;
            color: #fff;
        }
        .big-para{
            color: #fff;
            line-height: 45px;
        }
        .primary-btn{
            width: fit-content;
        }

    }

    .how_its_works_services {
        margin-top: 80px;
        margin-bottom: 80px;

        .works_card {
            display: flex;
            flex-direction: column;
            gap: 6px;
            align-items: center;
            padding: 12px;

            .works_logo-image {
                height: 90px;
                width: 90px;
                border-radius: 50%;
                padding: 10px;
                display: flex;
                background: rgba(187, 181, 181, 0.342);
                align-items: center;
                justify-content: center;

            }
        }
    }

    .why_choose_services {
        margin: 80px 0;

        .why_chose_services_card {

            background: #fff;
            border-radius: 12px;
            width: 90%;
            margin-bottom: 16px;
            color: #2B2B2B;
            transition: all 0.2s ease-in-out;
            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
            padding: 24px 24px;

            p {
                padding: 0;
                margin: 0;
            }
        }

        .why_chose_services_card:hover {
            background-color: #7A1EA3;
            color: #fff;
        }

    }

    .features_listing_services {
        margin-bottom: 80px;
        .transparent-btn{
            width: fit-content;
        }
    }

    .feature_listing_cards {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;

        .feature_listing_card {
            display: flex;
            align-items: center;
            border-radius: 15px;
            border: 1px solid rgb(218, 213, 213);
            overflow: hidden;
            flex-direction: column;
            width: 32%;
            gap: 6px;
            margin-bottom: 12px;
            transition: all 0.3s ease-in-out;
            align-items: center;

            img {
                height: 290px;
            }

            .bottom_content {
                padding: 16px 12px;
            }
        }

        .feature_listing_card:hover {
            img {
                transform: scale(1.1);
                transition: all 0.5s ease-in-out;
            }
        }
    }


    .features_category_services {
        margin-bottom: 80px;

        .category_cards {
            display: flex;
            justify-content: space-between;
            gap: 12px;

            .category_card {
                width: 24%;
                border-radius: 12px;
                border: 1px solid rgb(218, 213, 213);
                display: flex;
                flex-direction: column;
                gap: 8px;
                justify-content: center;
                align-items: center;
                padding: 20px 16px;
                .category_logo-image{
                    height: 90px;
                    width: 90px;
                    border-radius: 50%;
                    padding: 10px;
                    display: flex;
                    background: rgba(187, 181, 181, 0.342);
                    align-items: center;
                    justify-content: center;
    

                }
                .gray-btn{
                    border: none;
                    border-radius: 30px;
                    background: gainsboro;
                    color: #7A1EA3;
                    width: fit-content;
                    padding: 12px 20px;
                    font-size: 18px;
                    font-weight: 500;
                    margin-top: 10px;
                }
            }
        }
    }
}


@media (min-width:1600px){
    .service_banner_section{
        max-width: 2100px;
        margin: auto;
    }
}