.advertise_body {
    padding-top: 150px;

    .p-inputnumber-input {
        padding: 4px 12px;
    }

    .normal-heading {
        font-size: 24px;
        font-weight: 550;
        color: #2B2B2B;
        text-align: center;
    }

    .top_section_advertise {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        flex-direction: column;
        align-items: center;

        .big-heading {
            font-size: 45px;
            font-weight: 700;
            color: #2B2B2B;
        }

        .paragraph {
            font-size: 18px;
            font-weight: 400;
            color: rgba(88, 89, 129, 1);
        }

        .horizontal-primary {
            width: 35%;
            height: 4px;
            margin-top: 12px;
            background-color: #7a1ea3;
        }
    }

    .industry_section {

        .type_card {
            /* margin: 0 30px 0 0; */
            font-family: "Poppins", sans-serif !important;
            border-radius: 8px;
            cursor: pointer;
            overflow: hidden !important;
       
            
            width: 31%;
            transition: all 0.3s ease 0s;

            .rounded-image {
                overflow: hidden !important;
                transition: transform 0.5s ease-in-out 0.2s;

                img {
                    transition: all 0.35s ease-in;
                }
            }

           
            .h5-heading {
                font-size: 22px;
                line-height: 35px !important;
                font-weight: 500 !important;

            }



        }

        .type_card:hover {
            box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 12px 0px;
            transition: all 0.3s ease 0s;
        }

        .type_card:hover .rounded-image img {
            transform: scale(1.1);
        }

    }

    .reach-renters-section {

        margin: 70px 0 0 0;
        padding: 16px 0 16px 0;
        color: #FFF !important;
        width: 100%;

        a {
            color: #7a1ea3;
            font-weight: 500;
            text-decoration: underline !important;
        }

        .form_card_section {
            background-color: #fff;
            width: 500px;
            color: #2B2B2B !important;
            border-radius: 6px;
            padding: 28px 18px;

            .small-text {
                font-size: 13px;
            }
        }
    }

    .reach-renters-section_url1 {
        background: url('/public/Images/advertise-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
    }

    .reach-renters-section_url2 {
        background: url('/public/Images/advertise-bg2.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
    }

    .two_parts_section {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 70px 0;
    }
   
    .two_parts_section .transparent-btn{
            width: 50%;
        }
    

    .multifamily_card {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: center;

        a {
            color: #7a1ea3 !important;
            text-decoration: underline !important;
        }
    }

}

@media (max-width:576px) {
    h3{
        font-size: 24px !important;
    }
    .advertise_body {
        padding-top: 70px !important;
    }
    .top_section_advertise{
text-align: center;
    }
    .industry_section {

        .type_card {
            width: 100% !important;
            height: auto !important;
            .primary-btn{
                width: fit-content !important;
                font-size: 16px;
                padding: 8px 24px !important;
            }
        }

    }

    .reach-renters-section {

        margin: 40px 0 0 0 !important;
        padding: 16px 0 16px 0;
        color: #FFF !important;
        width: 100%;

      

        .form_card_section {
            background-color: #fff;
            width: 100% !important;
            color: #2B2B2B !important;
            border-radius: 6px;
            padding: 28px 18px;

            .small-text {
                font-size: 13px;
            }
        }
    }
    .two_parts_section{
        .transparent-btn{
            width: 100% !important;
        }
    }

}

@media (min-width:577px) and (max-width:976px) {
    .type_card {
        width: 48% !important;
        height: auto !important;
    }
    .two_parts_section{
        .transparent-btn{
            width: 70% !important;
        }
    }

}

@media (min-width: 1600px) {
    .reach-renters-section {
        max-width: 2100px;
        margin: auto !important;
    }
}