.lead_market_content {
    padding: 150px 0 00px 0;

    .learn_more_btn {
        padding: 10px 40px;
        font-size: 20px;
        display: flex;
        align-items: center;
        gap: 5px;
        background-color: #7A1EA3;
        border: none;
        font-weight: 450;
        border-radius: 30px;
        border: 2px solid #7A1EA3;
        color: #fff;
        width: fit-content;
        transition: all 0.2s ease-in-out;
    }

    .learn_more_btn:hover {
        color: #7A1EA3;
        background: transparent;
    }

    .big-heading {
        font-size: 48px;
        font-weight: 700;
        color: #2B2B2B;
        line-height: 120%;

        span {
            color: #7A1EA3;
        }
    }

    .big-para {
        font-size: 20px;
        font-weight: 400;
        color: #2B2B2B;
        line-height: 150%;
        margin-top: 10px;
    }

    .top_market_section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .big-heading {
            font-size: 60px;
        }

    }

    .quality_lead_section {
        margin: 100px 0 0 0;

        .leads_card {
            padding: 12px 0px;

            .lead_card_bottom {
                display: flex;
                flex-direction: column;
                /* align-items: center; */
                padding: 16px 10px;
             
                

                a {
                    color: #7A1EA3;
                    text-decoration: underline !important;
                }
            }

        }
    }

    .advance_marketing_section {
        margin: 70px 0 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }




}


.interested_section {

    background: url('/public/Images/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
margin-top: 70px;
    padding: 16px 0 16px 0;
    color: #FFF !important;
    width: 100%;

    a {
        color: #7a1ea3;
        font-weight: 500;
        text-decoration: underline !important;
    }

    .form_card_section {
        background-color: #fff;
        width: 500px;
        color: #2B2B2B !important;
        border-radius: 6px;
        padding: 28px 18px;

        .p-inputtextarea {
            padding: 4px 12px;
        }

        .p-inputnumber-input {

            padding: 4px 12px 4px 12px;

        }

        .small-text {
            font-size: 13px;
        }
    }
}

@media (max-width:576px) {
    .learn_more_btn {
        padding: 8px 20px !important;
        font-size: 16px !important;
        font-weight: 400 !important;

    }

    .interested_section {
        margin: 50px 0 0 0 !important;
        padding: 16px 0 16px 0;
        color: #FFF !important;
        width: 100%;
h1{
    font-size: 35px !important;
    text-align: center;
}
        .form_card_section {
            background-color: #fff;
            width: 100% !important;
            color: #2B2B2B !important;
            border-radius: 6px;
            padding: 28px 18px;

            .small-text {
                font-size: 13px;
            }
        }
    }

    .lead_market_content {
        padding-top: 70px !important;

        h4 {
            font-size: 18px !important;
        }

        h2 {
            font-size: 30px !important;
        }
    }

    .quality_lead_section {
        margin: 40px 0 !important;

        .leads_card {
            padding: 0 0px !important;

            .lead_card_bottom {

                padding: 16px 00px !important;


            }

        }
    }
}



@media (min-width:577px) and (max-width:976px) {

            .big-heading{
                font-size: 40px !important;
            }
    .lead_market_content {
        padding-top: 80px !important;
    }
    h3 {
        font-size: 28px !important;
    }

    h2 {
        font-size: 35px !important;
    }
    .quality_lead_section {
        margin: 40px 0 !important;

        .leads_card {
            padding: 0 0px !important;

            .lead_card_bottom {
               
                padding: 16px 00px !important; 


            }

        }
    }
}

@media (min-width:1600px) {
    .interested_section {
        max-width: 2100px;
        margin-left: auto;
        margin-right: auto;
    }
}