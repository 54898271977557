.main-nav-container {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;

  .nav-link {
    padding: 0;
  }
 
  .top-navbar {
    background-color: #7a1ea3;
    display: flex;
    padding: 0 109px;
    position: relative;
    align-items: center;
    color: rgba(255, 255, 255, 1);

    .location-dropdown-card {
      position: absolute;
      visibility: hidden;
      background: #fff !important;
      box-shadow: 0px 2px 5px rgb(92, 88, 88);
      overflow: hidden;
      border-radius: 8px;
      font-family: "Poppins", sans-serif !important;
      left: 8%;
      text-align: start;
      color: #2b2b2b;
      padding: 12px 20px;
      height: 0px;
      font-weight: 400 !important;
      /* width: fit-content !important; */
      width: 55%  ;
      top: 100%;
      .country-name{
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 4px;
        color: #2B2B2B;
        line-height: 16px;
        display: inline-block;
        svg{
          color: #7a1ea3 !important;
        }
      }
      .location-type-heading{
        font-size: 15px;
        font-weight: 600;
        margin: 4px 0;
      }
      .location-name{
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        padding: 3px 0;
      }
    }
    .location-name:hover{
      color: #7a1ea3;
    }

    .brand-icon {
      display: flex;
      cursor: pointer;
      align-items: center;
      gap: 12px;
      img{
        height:42px;

      }
      .sft-text {
        font-size: 24px;
        font-weight: 600;
        color: #fff !important;
      }
    }
    .drop-location {
      padding: 21px 14px;
      background-color: transparent;
      border-radius: 8px 8px 0px 0px !important;
      font-size: 15px;
      font-weight: 600 !important;
      text-align: center;
      height: 100%;
      text-wrap: nowrap;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      color: rgba(255, 255, 255, 1) !important;
      font-family: "Poppins", sans-serif !important;
      .up-icon {
        display: none;
      }
    }
    .drop-location:hover {
      background-color: #fff;
      color: rgba(0, 0, 0, 1) !important;
      .up-icon {
        display: inline-block;
      }
      .down-icon {
        display: none;
      }
    }
    .drop-location:hover {
      transition: all 0.3s ease-in-out;
      .location-dropdown-card {
        visibility: visible;
 
        transition: all 0.3s ease-in-out !important;
        top: 100%;
        height: 540px;
        width: 55%;
        z-index: 1000;
      }
    }
    .nav-item {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
    .nav-post-btn {
      color: #2b2b2b;
      background-color: #fff;
      width: 160px;
      padding: 6px 14px;
      border-radius: 8px;
      text-wrap: nowrap;
      align-items: center;
      border: none;
      font-size: 15px;
      font-weight: 650;
      .free-span {
        background-color: rgba(255, 199, 44, 1);
        font-size: 10px !important;
        font-weight: 400;
        margin: 0 !important;
        color: rgba(0, 0, 0, 1);
        border-radius: 4px;
        padding: 2px 4px;
      }
    }
    .sidebar-btn-menu{
     
      border: none;
  font-size: 16px;
  font-weight: 500;
      margin: 0;
      color: #2B2B2B;
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 6px 12px;
      background-color: #fff;
      border-radius: 30px;
    }
    .sidebar-btn {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      color: rgba(125, 125, 125, 1);
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 6px 12px;
      background-color: #fff;
      border-radius: 30px;
    }
    .mobile-sidebar-btn{
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      display: none;
   
      color: #fff;
      
     
    }

    .call-details {
      display: flex !important;
      align-items: center !important;
      gap: 16px;
      .phone-icon {
        color: #555151;
      }
      p {
        color: #b0a1b6;
        font-size: 13px;
        font-weight: 400;
        padding: 0;
        margin: 0;
      }
      .number {
        font-size: 16px;
        color: #2b2b2b;
        font-weight: 550;
      }
    }
    .contact-btn {
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      border: none;
      border-radius: 50%;
      background-color: #fff;
      position: relative;
      color: rgba(0, 0, 0, 1);
      .main-contact-card {
        position: absolute;

        padding-top: 20px;
        top: 0;
        right: -100px;
        z-index: 100;
        visibility: hidden;

        .contact-card {
          border-radius: 12px;
          text-align: start;
          width: 300px;

          box-shadow: 0 2px 5px #b0a1b6;
          height: 290px;
          background: #fff !important;
          color: rgba(43, 43, 43, 1);

          h6 {
            font-weight: 600;
            color: #2b2b2b;
            padding: 24px 0 14px 24px;
            font-size: 14px !important;
          }
          .second-call {
            position: relative;
            .second-call-card {
              position: absolute;
              border-radius: 12px;
              visibility: hidden;
              text-align: start !important;
              width: 250px;
              top: 45%;
              right: -70px;
              z-index: 100;
              box-shadow: 0 2px 5px #b0a1b6;
              height: 260px;
              background: #fff !important;
              color: rgba(43, 43, 43, 1);
              .heading-card {
                color: #b0a1b6;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
          .second-call:hover {
            transition: all 0.3s ease-in;
            .second-call-card {
              visibility: visible;
              top: 45%;
              transition: all 0.3s ease-in;
            }
          }

          .first-call:hover {
            background-color: rgba(243, 238, 238, 0.596);
          }
          .second-call:hover {
            background-color: rgba(243, 238, 238, 0.596);
          }
          .request-call-btn {
            width: 100%;
            background: transparent;
            color: #7a1ea3;
            font-weight: 600;
            padding: 8px 16px;
            display: flex;
            font-size: 14px;
            line-height: 20px;
            border: 1px solid #7a1ea3;
            border-radius: 6px;

            align-items: center;
            justify-content: center;
            gap: 8px;
          }
          .faq-text {
            font-size: 14px;
            color: #2b2b2b;
            font-weight: 400;
            span {
              color: #7a1ea3;
            }
          }
        }
      }
    }
    .contact-btn:hover {
      transition: all 0.3s ease-in-out;
      .main-contact-card {
        visibility: visible;
        top: 100%;
        transition: all 0.25s ease-in !important;
      }
    }

    .user-box {
      cursor: pointer;
      position: relative;
    
      .login-btn {
        width: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        height: 27px;
        border: none;
        border-radius: 50%;
        background-color: #fff;
        color: rgb(75, 73, 73);
        position: relative;
        transition: all 1s;
        .over-red {
          position: absolute;
          width: 10px;
          height: 10px;
          border: 2px solid white;
          background-color: rgb(204, 16, 16);
          border-radius: 50%;
          right: -2px;
          top: -2px;
        }
      }
      .down-icon {
        display: inline-block;
      }
      .up-icon {
        display: none;
      }
      .main-login-card {
        position: absolute;
        top: 0;
        right: -100px;
        z-index: 100;
        visibility: hidden;

        padding-top: 20px;
      }
      .login-card {
        border-radius: 12px;

        width: 240px;
        padding: 16px 24px;
        box-shadow: 0 2px 5px #b0a1b6;
        height: 295px;
        background-color: #fff;
        color: rgba(43, 43, 43, 1);

        h6 {
          font-weight: 500;
          margin-bottom: 25px !important;
          color: #7a1ea3;
        }
        .small-heading {
          font-size: 14px;
          font-weight: 500;
          padding: 2px 6px;
          color: #2b2b2b;
        }
        .small-heading:hover {
          background-color: rgba(243, 238, 238, 0.596);
        }
        .text-small {
          font-size: 14px;
          font-weight: 400;
          padding: 4px 6px;
          color: #2b2b2b;
        }
        .login-button {
          background-color: #7a1ea3;
          color: #fff;
          font-size: 15px;
          font-weight: 550;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 16px;
          width: 100%;
          border-radius: 25px;
        }
        .new-user-para {
          font-size: 14px;
          margin-top: 10px;
          color: #555151;
          font-weight: 400;
          a {
            font-size: 14px;
            font-weight: 550;
            color: #7a1ea3 !important;
          }
        }
        .text-small:hover {
          background-color: rgba(243, 238, 238, 0.596);
        }
      }
    }
    .user-box:hover {
      transition: all 0.3s ease-in-out;
      .down-icon {
        display: none;
      }
      .up-icon {
        display: inline-block;
      }
      .main-login-card {
        visibility: visible;
        top: 100%;
        transition: all 0.25s ease-in !important;
      }
    }
  }
 
  .navbar-container {
    /* background-color: rgba(19, 17, 17, 0.932); */
    width: 100% !important;
    padding: 12px 0 12px 0;
    /* filter: blur(8px);
        -webkit-filter: blur(8px); */
    /* backdrop-filter: blur(60px); */
    color: #fff !important;

    box-shadow: rgb(0, 0, 0) 10px 30px 30px -2px inset;
    /* a {
      color: #fff !important;
      .avtive{
        color: red !important;
      }
    } */

    .nav-bar {
      display: flex;
      gap: 12px;
      padding: 0 4%;
      justify-content: space-between;
      align-items: center;
      width: 100% !important;
      position: relative;
      .any-class {
        font-size: 16px;
        font-weight: 600;
        color: #2b2b2b !important;
        transition: all 0.25s ease-in-out;
        position: relative;
      }
      .service-class {
        font-size: 16px;
        font-weight: 600;
        color: #2b2b2b !important;
        transition: all 0.25s ease-in-out;
        /* position: relative; */
      }
      .nav-item {
        font-size: 16px;
        font-weight: 600;
        color: #ffff !important;
        cursor: pointer;
        transition: all 0.25s ease-in-out;
      }
      .buy-dropdown-card {
        position: absolute;
        visibility: hidden;
        padding-top: 20px;

        height: 0;
        width: fit-content !important;
        top: 150%;
      }
      .buy-dropdown-card2 {
        position: absolute;
        visibility: hidden;
        padding-top: 20px;
        left: 140px;
        height: 0;
        width: fit-content !important;
        top: 150%;
      }
      .buy-dropdown-card3 {
        position: absolute;
        visibility: hidden;
        padding-top: 20px;
        right: 20px;
        height: 0;
        width: fit-content !important;
        top: 150%;
      }
      .rent-dropdown-card {
        position: absolute;
        visibility: hidden;
        padding-top: 20px;

        width: fit-content;
        height: 0;
        width: fit-content !important;
        top: 150%;
      }
    }

    .buy-drop:hover {
      transition: all 0.25s ease-in-out;
      .buy-dropdown-card {
        visibility: visible;
        width: fit-content !important;
        transition: all 0.25s ease-in !important;
        top: 75%;
        height: auto;
        z-index: 1000;
      }
      .buy-dropdown-card2 {
        visibility: visible;
        width: fit-content !important;
        transition: all 0.25s ease-in !important;
        top: 75%;
        left: 140px;
        height: auto;
        z-index: 1000;
      }
      .buy-dropdown-card3 {
        visibility: visible;
        width: fit-content !important;
        transition: all 0.25s ease-in !important;
        top: 75%;
        right: 20px;
        height: auto;
        z-index: 1000;
      }
    }
    .rent-drop:hover {
      transition: all 0.25s ease-in-out;
      .rent-dropdown-card {
        visibility: visible;
        width: fit-content !important;
        transition: all 0.25s ease-in !important;
        top: 100%;
        height: auto;
      }
    }
    /* .any-class:hover {
      transition: all 0.25s ease-in-out;
      .buy-dropdown-card {
        visibility: visible;
        transition: all 0.25s ease-in !important;
        top: 0;
        height: auto;
      }
    } */
  }
}
.custom-sidebar{
  height: 100vh !important;
}
.brand-name-logo{
  display: flex;
  gap: 10px;
  align-items: center;
  .sft-name{
    font-size: 22px !important;
    font-weight: 700 !important;
    font-family: 'Poppins', sans-serif !important;
    color:#2B2B2B !important;
  }
}

@media (max-width: 480px) {
  .top-navbar {
    background-color: #7a1ea3;
    display: flex;
    padding: 6px 20px 6px 10px !important;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    .brand-icon {
    img{
      height:25px !important;
    }
      .sft-text {
        font-size: 18px !important;
        font-weight: 650 !important;
        color: #fff;
      }
    }
    .drop-location {
      padding: 12px 10px !important;
   display: none !important;
      background-color: transparent;
      border-radius: 8px 8px 0px 0px !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      text-align: center;
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      color: rgba(255, 255, 255, 1) !important;
      font-family: "Poppins", sans-serif !important;
      .up-icon {
        display: none;
      }
    }
    .drop-location:hover {
      background-color: #fff;
      color: rgba(0, 0, 0, 1) !important;
      .up-icon {
        display: inline-block;
      }
      .down-icon {
        display: none;
      }
    }
    .nav-item {
      color: #fff;
      display: none;
      font-size: 14px;
      font-weight: 600;
    }
    .nav-post-btn {
      color: #2b2b2b;
      background-color: #fff;
      width: 140px !important;
      padding: 4px 10px !important;
      /* border: 2px solid red !important; */
      border-radius: 8px;
      align-items: center;
      border: none;
      font-size: 13px !important;
      font-weight: 550 !important;
      .free-span {
        background-color: rgba(255, 199, 44, 1);
        font-size: 10px !important;
        font-weight: 400;
        margin: 0 !important;
        color: rgba(0, 0, 0, 1);
        border-radius: 4px;
        padding: 0px 4px !important;
      }
    }
    .contact-btn {
      display: none !important;
    }
    .sidebar-btn{
      display: none !important;
    }
    .sidebar-btn-menu{
      display: none !important;
    }
    .mobile-sidebar-btn{
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      display: block !important;
      color: #fff; 
    }

    .user-box{
      display: none !important;
    }
    
  }
  .navbar-container{
    display: none !important;
  }
}
@media (min-width: 481px) and (max-width:767px){
  .top-navbar {
    background-color: #7a1ea3;
    display: flex;
    padding: 8px 24px 8px 24px !important;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    .brand-icon {
    img{
      height:30px !important;
    }
      .sft-text {
        font-size: 22px !important;
        font-weight: 650 !important;
        color: #fff;
      }
    }
    .drop-location {
      padding: 12px 10px !important;
   display: none !important;
      background-color: transparent;
      border-radius: 8px 8px 0px 0px !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      text-align: center;
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      color: rgba(255, 255, 255, 1) !important;
      font-family: "Poppins", sans-serif !important;
      .up-icon {
        display: none;
      }
    }
    .drop-location:hover {
      background-color: #fff;
      color: rgba(0, 0, 0, 1) !important;
      .up-icon {
        display: inline-block;
      }
      .down-icon {
        display: none;
      }
    }
    .nav-item {
      color: #fff;
      display: none;
      font-size: 14px;
      font-weight: 600;
    }
    .nav-post-btn {
      color: #2b2b2b;
      background-color: #fff;
      width: 150px !important;
      padding: 4px 10px !important;
      /* border: 2px solid red !important; */
      border-radius: 8px;
      align-items: center;
      border: none;
      font-size: 14px !important;
      font-weight: 600 !important;
      .free-span {
        background-color: rgba(255, 199, 44, 1);
        font-size: 12px !important;
        font-weight: 400;
        margin: 0 !important;
        color: rgba(0, 0, 0, 1);
        border-radius: 4px;
        padding: 2px 4px !important;
      }
    }
    .contact-btn {
      display: none !important;
    }
    .sidebar-btn{
      display: none !important;
    }
    .sidebar-btn-menu{
      display: none !important;
    }
    .mobile-sidebar-btn{
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      display: block !important;
      color: #fff; 
    }

    .user-box{
      display: none !important;
    }
    
  }
  .navbar-container{
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width:1230px){
  .top-navbar {
    background-color: #7a1ea3;
    display: flex;
    padding: 0px 46px 0px 46px !important;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    .brand-icon {
    img{
      height:36px !important;
      margin: 8px 0
    }
      .sft-text {
        font-size: 26px !important;
        font-weight: 650 !important;
        color: #fff;
      }
    }
    .drop-location {
      padding: 12px 14px !important;
      height: 100% !important;
   /* display: none !important; */
      background-color: transparent;
      border-radius: 8px 8px 0px 0px !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      text-align: center;
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      color: rgba(255, 255, 255, 1) !important;
      font-family: "Poppins", sans-serif !important;
      .up-icon {
        display: none;
      }
    }
    .drop-location:hover {
      background-color: #fff;
      color: rgba(0, 0, 0, 1) !important;
      .up-icon {
        display: inline-block;
      }
      .down-icon {
        display: none;
      }
    }

    .location-dropdown-card {
      position: absolute;
      visibility: hidden;
      background: #fff !important;
      box-shadow: 0px 2px 5px rgb(92, 88, 88);
      overflow: hidden;
      border-radius: 8px;
      font-family: "Poppins", sans-serif !important;
      left: 8%;
      text-align: start;
      color: #2b2b2b;
      padding: 12px 20px;
      height: 0px;
      font-weight: 400 !important;
      /* width: fit-content !important; */
      width: 85% !important;
      top: 100%;
      .country-name{
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 4px;
        color: #2B2B2B;
        line-height: 16px;
        display: inline-block;
        svg{
          color: #7a1ea3 !important;
        }
      }
      .location-type-heading{
        font-size: 15px;
        font-weight: 600;
        margin: 4px 0;
      }
      .location-name{
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        padding: 3px 0;
      }
    }

    .drop-location:hover {
      transition: all 0.3s ease-in-out;
      .location-dropdown-card {
        visibility: visible;
        transition: all 0.3s ease-in-out !important;
        top: 100%;
        height: 540px;
        width: 85% !important;
        z-index: 1000;
      }
    }
    .nav-item {
      color: #fff;
      display: none;
      font-size: 14px;
      font-weight: 600;
    }
    .nav-post-btn {
      color: #2b2b2b;
      background-color: #fff;
      width: 160px !important;
      padding: 6px 12px !important;
      /* border: 2px solid red !important; */
      border-radius: 8px;
      align-items: center;
      border: none;
      font-size: 16px !important;
      font-weight: 600 !important;
      .free-span {
        background-color: rgba(255, 199, 44, 1);
        font-size: 13px !important;
        font-weight: 400;
        margin: 0 !important;
        color: rgba(0, 0, 0, 1);
        border-radius: 4px;
        padding: 2px 4px !important;
      }
    }
    .contact-btn {
      display: none !important;
    }
    .sidebar-btn{
      display: none !important;
    }
    .sidebar-btn-menu{
      display: none !important;
    }
    .mobile-sidebar-btn{
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      display: block !important;
      color: #fff; 
    }

    .user-box{
      display: none !important;
    }
    
  }
  .navbar-container{
    display: none !important;
  }
}
/* @media (min-width:768px) and (max-width:978px){} */
@media (min-width: 1600px) {
  .main-nav-container {
    max-width: 2100px;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 0;
    width: 100%;
  }
  .sft-text {
    font-size: 32px !important;
    font-weight: 600;
    
  }

  .top-navbar {
    background-color: #7a1ea3;
    display: flex;
    padding: 0 50px !important;
    align-items: center;
    color: rgba(255, 255, 255, 1);
   
  }
}
@media (min-width: 1920px) {
  .drop-location {
    margin-left: 79px !important;
 
  }
  .location-dropdown-card {
    position: absolute;
    visibility: hidden;
    background: #fff !important;
    box-shadow: 0px 2px 5px rgb(92, 88, 88);
    overflow: hidden;
    border-radius:0px 0px 8px 8px !important;
    font-family: "Poppins", sans-serif !important;
    left: 16% !important;
    text-align: start;
    color: #2b2b2b;
    padding: 12px 20px;
    height: 0px;
    font-weight: 400 !important;
    /* width: fit-content !important; */
    width: 55% !important;
    top: 100%;}
    
}
/* for right sidebar  */

.p-sidebar-mask {
  align-items: self-start !important;
}
.p-sidebar-right .p-sidebar {
  width: 21.3rem;
  height: 80vh;
  font-family: 'Poppins', sans-serif !important;
}
.p-sidebar .p-sidebar-header {
  padding: 8px 19px 8px 24px !important;
  
  background-color: #e2e2e233;
  .p-sidebar-close {
    svg {
      color: #000000 !important;
    }
  }
  .brand-icon-sidebar{
   
cursor: pointer;
display: flex;
align-items: center;
gap: 12px;
color: #2B2B2B;
font-size: 28px;
font-weight: 700;
  }
  .brand-icon-sidebar img{
    width: 38px;
  }
}
/* .sidebar-menu-body{
  
} */
.sidebar-content-body {
  /* border: 1px solid red !important; */
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin;

  .my-activity-div {
    padding: 8px 8px 6px 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #2b2b2b;
  }
  .accordian-header{
    padding: 6px 8px 6px 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400 !important;
    font-family: "Poppins", sans-serif !important;
    cursor: pointer;
    display: flex;
    color: #2b2b2b !important;
    align-items: center;
    gap: 10px;
    a{
      color: #2B2B2B !important;
      font-size: 14px;
      font-weight: 400 !important;
      svg{
        color: #7c7878;
      }
    }
   
  }

  .accordian-header:hover {
    background-color: #f4f5f7;
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: none;
    border:none;
    color: #2b2b2b;
    
    
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0;
  border:none !important;
  color: #2b2b2b;
  background: transparent;
  font-weight: 550 !important;
 box-shadow: none;
  transition: box-shadow 0.2s;
}
.p-accordion .p-accordion-content {
  padding: o;
  border: none;
  background: transparent;
  color: #2b2b2b;
 
}
.login-ragister-btn {
  font-size: 14px !important;
  font-weight: 500;
  color: #7a1ea3 !important;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  svg {
    color: #2b2b2b;
  }
  text-transform: uppercase;
}
  .post-property-btn {
    padding: 10px 8px 10px 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 550;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #2b2b2b;
    .tag {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #219653;
      border: 1px solid #d7f2e3;
      font-weight: 600;
      line-height: 20px;
      font-size: 12px;
      padding:0px 4px;
     
      text-transform: uppercase;
      color: #ffffff;
      border-radius: 2px;
    }
  }
  /* .post-property-btn:hover {
    background-color: #f4f5f7;
  } */
  .divider-down {
    width: 93%;
    margin-left: auto;
    height: 1px;
    background-color: #ebecf0 !important;
  }
  .simple-sidebar-btn {
    padding: 4px 20px;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 14px;
    color: #2b2b2b;
    .new-tag {
      font-size: 8px;
      color: #fff;
      font-weight: 600;
      padding: 2px 6px;
      background-color: #7a1ea3;
      border: 1px solid #d7f2e3;
      display: flex;
      align-items: center;
      border-radius: 3px;
      margin-left: -8px;
    }
    cursor: pointer;
    svg {
      color: #78717a;
    }
  }
  .simple-sidebar-btn:hover {
    background-color: #f4f5f7;
  }
}

.testactive {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.backward-header{
  color: #2B2B2B !important;
  font-size: 16px;
  font-weight: 550;
  span{
    cursor: pointer !important;
    margin-right: 10px;
  }
}
