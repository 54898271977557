h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
}

h1 {
  font-size: 45px !important;
}

h2 {
  font-size: 40px !important;
}

h3 {
  font-size: 32px !important;
}

h4 {
  font-size: 24px !important;
}

h5 {
  font-size: 20px !important;
}

h6 {
  font-size: 16px !important;
}

a {
  text-decoration: none !important;
}

.gold-para {
  font-size: 17px;
  font-weight: 400;
  color: #e5b57b;
}

.simple-btn {
  background: none;
  border: none;
  padding: 12px 32px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px;
}

.simple-btn:hover {
  background-color: #fff;
  color: #000000;
}

.white-btn {
  background-color: #fff;
  color: #000000;
  border: none;
  padding: 12px 32px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px;
}

.color-primary {
  color: #7a1ea3;
}

.color-gray {
  color: #8F90A6;
}

.primary-btn {
  border: none;
  background-color: #7a1ea3;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  color: #fff;
  padding: 16px 35px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.transparent-btn {
  background: transparent;
  border: 1px solid #7a1ea3;

  font-size: 17px;
  font-weight: 500;
  width: 100%;
  color: #7a1ea3;
  padding: 16px 35px;
  border-radius: 12px;
  transition: 0.2s all ease-in-out;
}

.transparent-btn:hover {
  background: #7a1ea3;
  color: #fff;

}

.cancel_btn {
  padding: 18px 30px 20px 30px;
  gap: 10px;
  border-radius: 12px;
  background-color: #7a1ea3;
  color: #ffffff;
  text-align: center;
  border: none;
}

.end_footer_section {
  margin: 70px 0;
  /* background:url('/public/Images/end-bg-image.png'); */
  background-color: #dfc6eb4b;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  padding: 48px 36px;
  color: #2B2B2B;

  .left_content {
    width: 55%;
  }

  .normal_tag {
    background-color: #7A1EA3;
    padding: 4px 16px;
    color: #fff;
    width: fit-content;
  }
}


@media (max-width: 576px) {

  .end_footer_section {
    padding: 24px 16px !important;
    margin: 50px 0 !important;

    h2 {
      font-size: 28px !important;
    }

    .normal_tag {
      font-size: 12px !important;
      padding: 4px 8px !important;
    }

    .primary-btn {
      width: 100% !important;
      padding: 4px 16px !important;
      font-size: 13px !important;
    }

    .left_content {
      width: 100% !important;
    }
  }

  .primary-btn {
    background: none;
    border: none;
    background-color: #7a1ea3;
    font-size: 20px;
    font-weight: 500;
    line-height: 37px;
    width: 60%;
    color: #fff;
    padding: 10px 20px;
    border-radius: 14px;
  }

  .white-btn {
    background-color: #fff;
    color: #000000;
    border: none;
    padding: 8px 10px !important;
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
  }

  .simple-btn {
    background: none;
    border: none;
    padding: 8px 10px !important;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
  }
}