.footer-container {
  background-color: #151c22;
  color: #fff;
padding: 60px 0px;
  .brand-name-gold {
    color: #fff  !important;
  }
  a {
    color: #e9e9e9;
    font-size: 15px;
    font-weight: 400;
    text-wrap: nowrap;
  }
  a:hover {
    color: #e9e9e9;
  }
  .line {
    width: 58px;
    height: 4.5px;
    background-color: #FFFFFF;
    border-radius: 8px; /* Adjust as needed */
    margin-top: -7px; /* Adjust as needed */
    margin-bottom: 8px;
  }
    h5{
      text-wrap: nowrap;
      margin: 0px !important;
    }
    .footer-down-text{
      font-size: 13px;
      color: #999999;
      font-weight: 400;
text-align: justify;
      margin-top: 8px;
      line-height: 22px;
      a{
        color: #fff;
        font-size: 13px;
        font-weight: 500;
      }
      span{
        color: #fff;
        font-size: 13px;
        font-weight: 500;
      }
    }
   
   
 .Company-description{
  font-size: 15px;
  color: #e9e9e9;
  font-weight: 300;
  text-align: justify;
 }
 
    .footer-right-box {
 p{
  svg{
    color: #7A1EA3;
  }
 }
 .social-media-div{
            display: flex;
            flex-wrap: wrap;
            /* justify-content: center; */
            /* align-items: center; */
            gap: 10px;
            margin: 0px 0px 00px 0;
          

        }
        .footer-form{
            
            display: flex;
            flex-direction: column;
            gap: 22px;
            input{
                border: none;
                outline: none;
                padding: 12px 17px;
                width: 100%;
                background-color: #fff;
                color: #151C22;
                font-weight: 300;
                border-radius: 10px;
                ::placeholder{
                    color: #151C22;
                }
            }
        }
        .appstore-div{
          display: flex;
          margin-top: 15px;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          img{
            width: 48%;
            cursor: pointer;
          }
        }
   
      }
  
 


     
}
@media (max-width:480px) {
  .footer-container{
    display: block;
    /* flex-wrap: wrap; */
    overflow: hidden;
  }
  .space{
    gap: 1rem;
  }
  .footer-down-text{
    font-size: 15px;
    color: #999999;
    font-weight: 400;
text-align: start !important;
    margin-top: 8px;
    line-height: 22px;}
  .footer-social-media-div{
    width: 100% !important;
  }
  .multi-links{
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }
  a{
    text-wrap: wrap !important;
  }
  h5{
    text-wrap: wrap !important;
    margin: 0px !important;
  }

  .footer-left-side {
  
   padding: 20px 0 0 30px !important;
    width: 100%;
    .blank-div{
      display: none;
    }
  
  }
  .footer-right-side {
   
    width: 100% !important;
    .footer-right-box {
      width: 100% !important;
      background-size: contain;
    }
  }
  .footer-right-side {
   
    .footer-right-box {
        background: url("/public/Images/footer-image.png");
      

        padding: 15px 10px 15px 10px !important;
        background-size: cover;
        .social-media-div{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin: 20px 0px !important;
          

        }
        .footer-form{
            margin: 0 30px;
            display: flex;
            flex-direction: column;
            gap: 22px;
            input{
                border: none;
                outline: none;
                padding: 10px 16px !important;
                background-color: #151C22;
                color: #fff;
                font-weight: 300;
                border-radius: 10px;
                ::placeholder{
                    color: #999999;
                }
            }
        }
      }
  }
}
@media (min-width:481px) and (max-width:767px) {
  .footer-container{
    display: block;
    /* flex-wrap: wrap; */
  }
  .footer-left-side {
  
   padding: 20px 0 0 30px !important;
    width: 100%;
    .blank-div{
      display: none;
    }
  
  }
 
  .footer-right-side {
    width: 100% !important;
    .footer-right-box {
        background: url("/public/Images/footer-image.png");
        width: 100% !important;
        background-size: contain;
        padding: 20px 10px 24px 24px !important;
        background-size: cover;
        .social-media-div{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 16px;
            margin: 20px 0px !important;
          

        }
        .footer-form{
            margin: 0 30px;
            display: flex;
            flex-direction: column;
            gap: 22px;
            input{
                border: none;
                outline: none;
                padding: 12px 17px;
                background-color: #151C22;
                color: #fff;
                font-weight: 300;
                border-radius: 10px;
                ::placeholder{
                    color: #999999;
                }
            }
        }
      }
  }
}
@media (min-width:768px) and (max-width:1167px) {
  .footer-container{
    display: block;
    /* flex-wrap: wrap; */
  }
  .footer-left-side {
   padding: 20px 0 0 30px !important;
    width: 100% !important;
    .blank-div{
      display: none;
    }
  
  }
 
  .footer-right-side {
    width: 100% !important;
    .footer-right-box {
        background: url("/public/Images/footer-image.png");
        width: 100% !important;
        background-size: contain;
        padding: 20px 10px 24px 24px !important;
        background-size: cover;
        .social-media-div{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 16px;
            margin: 20px 0px !important;
          

        }
        .footer-form{
            margin: 0 30px;
            display: flex;
            flex-direction: column;
            gap: 22px;
            input{
                border: none;
                outline: none;
                padding: 12px 17px;
                background-color: #151C22;
                color: #fff;
                font-weight: 300;
                border-radius: 10px;
                ::placeholder{
                    color: #999999;
                }
            }
        }
      }
  }
}



@media (min-width: 1600px){
  .footer-container{
      max-width: 2100px;
      margin: auto;
  }
  
  }