.body_section_premium_access {

    .top_section {
        padding-top: 250px;
        padding-bottom: 100px;
        background: url(/public/Images/premium_access_banner.png);
        width: 100%;
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;

        .countdown_div {
            width: 340px;
            border: 2px solid #7A1EA3;
            margin-top: 20px;
            background: #7A1EA3;
            color: #fff;
            border-radius: 8px;
            padding: 12px 16px;
        }

        .countdown_div:hover {
            border-color: #7A1EA3;
        }

        .big-heading {
            font-size: 60px;
            font-weight: 700;
            color: #ffffff;
            line-height: 125%;
            width: 50%;
        }

        .big-para {
            font-size: 16px;
            font-weight: 400;
            width: 52%;
            color: #fff;
            line-height: 150%;
            margin-top: 6px;
        }

        h3 {
            color: #fff;
        }



    }

    .top_header_premium {
        width: 70%;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .growing_section {
        margin: 70px 0 40px 0;
    }

    .cloud_card {
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: #2B2B2B;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        width: 30%;
        cursor: pointer;
        border-radius: 12px;
        min-width: 320px;
        padding: 50px 35px 50px 35px;
        background-color: #fff;
    }

    .cloud_card:hover {

        background: #d8bae634;
    }

    .plans_content {
        margin: 70px 0;
    }

    .plans-section {


        .plan-cards {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 25px;
        }

        .plan-card {
            width: 19%;
            background: #fff !important;
            color: #2B2B2B !important;
            border-radius: 12px;
            overflow: hidden;
            padding: 0px;
            border: 1px solid transparent;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            transition: all 0.3s ease-in-out;

            .plan_card_header {
                padding: 24px 16px 8px 16px;
                border-bottom: 1px solid #e2e2f0;
                margin-bottom: 10px;
                transition: all 0.2s ease-in-out;
            }
            .active{
                background: linear-gradient(to right, crimson, #7A1EA3);
                color: #fff !important; 
                svg path{
                    fill: #fff;
                }
                .color-gray {
                    color: #fff !important;
                }
            }

            .plan_card_bottom {
                padding: 16px 12px 36px 12px;
            }

            .feature-text {
                font-size: 12px;
                color: #8F90A6;
            }

            .primary-btn {

                margin-top: 10px;
                padding: 12px 20px;
                font-size: 18px;
            }

            .include_services span:hover {
                color: #7A1EA3;
                cursor: pointer;
            }

            h4 span {
                font-size: 15px;
            }

        }

        .plan-card:hover {
            border: 1px solid #7A1EA3;
            transform: scale(1.05);
        }

        .plan-card:hover .plan_card_header {
            background: linear-gradient(to right, crimson, #7A1EA3);
            color: #fff !important;

           


        }
        .plan-card:hover .plan_card_header svg path{
            fill: #fff;
        }
        .plan-card:hover .color-gray {
            color: #fff !important;
        }
    }

    .advance_priority_section {
        margin: 100px 0;

        .icon-div {
            width: 100%;
        }
    }

  
}


@media (max-width:480px) {
    h2 {
        font-size: 28px !important;
    }

    .plans_offer_dialog_header {
        font-size: 16px !important;
        gap: 4px !important;
        padding: 16px 10px 16px 12px !important;

    }


    .plans_offer_dialog_body {
        padding: 0px 16px 16px 12px !important;
        font-family: 'Poppins', sans-serif !important;
        background: none !important;

        h4 {

            font-size: 15px !important;

        }

        .primary-btn {

            font-size: 16px !important;
            padding: 8px 48px !important;
        }
    }

    .dialog_para {
        font-size: 16px !important;
        padding: 4px 0 !important;
        font-weight: 400 !important;
    }


    .top_header_premium {
        width: 100% !important;

    }

    .top_section {
        padding-top: 100px !important;
        padding-bottom: 50px;
        width: 100%;

        .countdown_div {
            width: 240px !important;
            border: 2px solid #7A1EA3;
            margin-top: 15px !important;
            background: #7A1EA3;
            color: #fff;
            border-radius: 4px !important;
            padding: 12px 10px !important;
        }

        .big-heading {
            font-size: 35px !important;
            font-weight: 700;
            color: #ffffff;
            line-height: 125%;
            width: 100% !important;
        }

        h3 {
            font-size: 20px !important;
        }

        .big-para {

            font-weight: 400;
            width: 80% !important;
            color: #fff;
            line-height: 150%;
            margin-top: 20px;
        }

    }

    .cloud_card {
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: #2B2B2B;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        width: 96% !important;
        margin: 10px auto;
        cursor: pointer;
        border-radius: 12px;
        min-width: 200px !important;
        padding: 50px 35px 50px 35px;
        background: #7A1EA31A;
    }

    .plans-section {

        .plan-card {
            width: 98% !important;
            margin: 16px auto;

            .primary-btn {
                width: 100% !important;
                font-size: 16px !important;
                padding: 4px 16px !important;
            }

        }


    }

   
}

@media (min-width:481px) and (max-width:986px) {

    .plans_offer_dialog_header {
        font-size: 24px !important;
        gap: 4px !important;
        padding: 16px 10px 16px 12px !important;

    }


    .plans_offer_dialog_body {
        padding: 10px 16px 16px 12px !important;
        font-family: 'Poppins', sans-serif !important;
        background: none !important;

        h4 {

            font-size: 18px !important;

        }

        .primary-btn {

            font-size: 16px !important;
            padding: 8px 48px !important;
        }
    }

    .dialog_para {
        font-size: 19px !important;
        padding: 8px 0 !important;
        font-weight: 400 !important;
    }




    .top_header_premium {
        width: 100% !important;

    }

    .plan-cards {
        justify-content: start !important;
    }

    .plan-card {
        width: 47% !important;
        margin-top: 16px;
        margin-bottom: 12px;
        margin-right: 2%;


    }
}


@media (min-width: 1600px) {
    .top_section {
        max-width: 2100px;
        margin: auto !important;
    }
}


.plans_offer_dialog_header {
    font-size: 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif !important;
    color: #fff;
    margin-bottom: 20px;
  
}


.close-popup-icon{
    display: flex;
    justify-content: end;
    color: #fff;
    margin-bottom: 20px;
   
    .close-btn-popup{
        cursor: pointer;
        border: 1px solid #fff;
        border-radius: 50%;
    }
  
 
}
.plans_offer_dialog_body {
    padding: 10px 20px 45px 42px;
    font-family: 'Poppins', sans-serif !important;
    background: url(/public/Images/dialog-bg.png);
    background-size: cover;
    
   
    background-position: center;

    h4 {
        color: orange;

    }

    .primary-btn {
        width: fit-content;
        margin-top: 18px;
        padding: 12px 48px;
    }
}

.dialog_para {
    font-size: 20px;
    padding: 12px 0;
    color: #fff;
    font-weight: 500;
}