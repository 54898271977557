@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
    box-sizing: border-box !important;
    background-color: #FAFAFA !important;

}

.no-scroll {
    overflow: hidden;
}
.rounded_corner{
    border-radius: 12px !important;
}
.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
    border-color: #7A1EA3;
}

.p-radiobutton.p-highlight .p-radiobutton-box {
    border-color: #7A1EA3;
    background: #7A1EA3;
}

.p-radiobutton.p-highlight .p-radiobutton-box:hover{
    border-color: #7A1EA3;
}

.p-dialog-header-icons{
    padding: 12px !important;
}

.p-dialog .p-dialog-content:last-of-type {
    border-radius: 5px !important;
}

.p-paginator{
background: transparent;
color: #2B2B2B;
display: flex;
gap: 12px;
}
.p-paginator-pages{
    display: flex;
gap: 16px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
    background-color: #7A1EA3;
    color: #fff;
    border: none;
    border-radius: 12px;
}

.p-paginator .p-paginator-pages .p-paginator-page{
    border: 1px solid  #8F90A6;
    border-radius: 12px;
    font-size: 15px;
}