.main{
    /* text-align: center; */
    font-family: 'Poppins', sans-serif !important;
  }
  .flex_row{
    justify-content: end;
    display: flex;
    padding-bottom: 0px;
  }
  
.thumbnail{
 position: relative;
  p{
    font-size: 15px;
    color: #2B2B2B;
    margin: 4px 0 8px 0;
  }

 
}

.image-container {
  position: relative;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;
  
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
  opacity: 1; /* Initially invisible */
  transition: opacity 0.3s ease; /* Add transition for fade effect */
}
.blank-height-div{
  height: 134px;
}
.white-above-box{
  background-color: #fff;
  display: none;
  padding: 12px; 
  position: relative;
  border-radius: 10px;
  /* box-shadow: 0px 2px 5px  rgb(238, 236, 236); */
  margin-bottom: 10px;
  .brand-name{
  h6{
    width: 180px ; 
    white-space: nowrap; 
    overflow: hidden;
    margin-bottom: 5px !important;
    text-overflow: ellipsis;
  }

  }
}

.clicked{
  border: 3px solid rgb(228, 178, 224);
  /* border-radius: 12px; */
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
    opacity: 0; /* Initially invisible */
    transition: opacity 0.3s ease; /* Add transition for fade effect */
  }
}
.btns{
  height: 40px;
  width: 40px;
  display: none;
  border-radius: 5px;
  background: grey;
}
@media (max-width:486px) {
  .thumbnail{
    display: none !important;
  }
  .white-above-box{
    display: block !important;
    position: relative;
  }
  
  .blank-height-div{
    height: 0px !important;
  }
}

@media (min-width:487px) and (max-width:1200px) {
  .thumbnail{
    display: none !important;
  }
  .white-above-box{
    display: block !important;
  }
  .blank-height-div{
    height: 0px !important;
  }

}