.body_section_news {
    .top_section {
      padding-top: 150px;
      padding-bottom: 100px;
    }
  
    .addborder {
      border-top: 3px solid #7a1ea3;
      margin-top: 0px;
    }
  
    .news-card {
      display: flex;
      overflow: hidden;
      margin-bottom: 20px;
      cursor: pointer;
    }
  
    .news-image {
      position: relative;
      flex: 1;
      transition: transform 0.3s ease;
      width: 247px;
      height: 145px;
      overflow: hidden;
    }
  
    .news-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }
  
    .news-image:hover img {
      transform: scale(1.07);
    }
  

    a:hover{
      color: #7a1ea3 !important;
      cursor: pointer;
    }
    .posted-on {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      padding: 5px 10px;
      border-radius: 3px;
      font-size: 14px;
      background-color: #7a1ea3;
    }
  
    .news-details {
      flex: 2;
      padding: 0px 20px;
      display: flex;
      flex-direction: column;
    }


    .mediaGallery_img{
      cursor: pointer;
    }
  
    .news-details h4 {
      margin: 0;
      font-size: 1.3rem;
      margin-bottom: 20px;
    }
  
    .news-details h4:hover {
      color: #7a1ea3;
    }
  
    .news-details p {
      margin: 0;
      margin-bottom: 20px;
      line-height: 1.6;
      font-size: 0.8rem;
    }
  
    .posted-by,
    .categories {
      color: #888;
      font-size: 12px;
    }
  
    .posted-pointer:hover {
      color: #7a1ea3 !important;
      cursor: pointer;
    }
  
    .right-side h6 {
      padding-top: 20px;
      line-height: 24px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 15px;
    }
  
    ul {
      list-style-type: none;
      padding-left: 0px !important;
    }

       li a {
        display: block;
        padding: 0 30px;
        line-height: 20px;
        position: relative;
        text-decoration: none ;
        color: black !important;
        
     }
  
    .right-side li {
        margin-bottom: 20px;
        padding: 14px 0px;
        border-bottom: 1px solid #f0f0f0;
        font-weight: 400;
        font-size: 0.9rem;
        position: relative;
        color: black !important;
    }


  
    .right-side a:before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      bottom: 0;
      width: 3px;
      background-color: #7a1ea3;
    }

    .gallery-icon{
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
    }

    .cat-item {
      background-color: rgba(240, 240, 240, 0.5) !important;
      margin-bottom: 5px !important;
      border-radius: 3px !important;
    }

    .cat-item a:before{
      display: block;
      content: "";
      border-color: rgba(35, 77, 212, 1);
      background-color: rgba(35, 77, 212, 1);
      position: absolute;
      top: 9px;
      left: 17px;
      width: 4px;
      height: 4px;
      border-width: 1px;
      border-style: solid;
      transform: rotate(45deg);
    }


    .download-btn {

      padding: 10px;
      border-radius: 3px;
      outline: none;
      background-color: #7a1ea3 !important;
      cursor: pointer;
      color: white !important;
      border: none;
    }

    .search-bar {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      position: relative;
    }
    
    .search-bar .p-input-icon-left {
      width: 100%;
    }
    
    .search-bar input {
      width: 100%;
      padding: 10px 30px 10px 10px; 
    }
    
    .search-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #7a1ea3; 
    }



    .dialog_class{
      display: flex;
      justify-content: center;
    }
    
    

    /*  Responsive Css */


     @media (max-width :480px){

      .news-card {
        display: flex;
        overflow: hidden;
        margin-bottom: 20px;
        cursor: pointer;
        flex-direction: column ;
        align-items: center;
      }

      .news-image {
        position: relative;
        flex: 1 1;
        transition: transform 0.3s ease;
        width: 100%;
        height: 145px;
        overflow: hidden;
        margin-bottom: 30px;
    }


    .news-details {
      flex: 2 1;
      padding: 0px;
      display: flex;
      flex-direction: column;
  }


  .top_section {
    padding-top: 60px !important;
    padding-bottom: 50px;
    width: 100%;
}


.gallery-icon {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}



.title{

    font-size: 1rem;
    line-height: 32px;
    margin-bottom: 15px !important;
}



.mediaGallery_img {
  width: 70px;
  height: 70px;
}

     }


     @media(min-width: 481px) and (max-width:999px){

      .top_section {
        padding-top: 80px !important;
        padding-bottom: 50px;
        width: 100%;

    }
    
  
     }

        

  }
  