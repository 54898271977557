.body_section_MarketInsight {
    .top_section {
        padding-top: 250px;
        padding-bottom: 100px;
        background: url(/public/Images/MarketInsightBanner.png);
        width: 100%;
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;

        .countdown_div {
            width: 340px;
            border: 2px solid #7A1EA3;
            margin-top: 20px;
            background: #7A1EA3;
            color: #fff;
            border-radius: 8px;
            padding: 12px 16px;
        }

        .countdown_div:hover {
            border-color: #7A1EA3;
        }

        .big-heading {
            font-size: 60px;
            font-weight: 700;
            color: #ffffff;
            line-height: 125%;
            width: 50%;
        }

        .big-para {
            font-size: 16px;
            font-weight: 400;
            width: 52%;
            color: #fff;
            line-height: 150%;
            margin-top: 6px;
        }

        h3 {
            color: #fff;
        }
    }

    .image-container {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 20px;
        height: 80%;
        width: 100%;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        transition: transform 0.3s ease; 
    }

    .image-container:hover .image {
        transform: scale(1.07); 
     
    }

    .overlay-text {
        position: absolute;
        bottom: 20px; 
        left: 50%;
        transform: translateX(-50%);
        color: white;
  
        padding: 10px;
        border-radius: 8px;
        text-align: center;
    }

    
    .overlay-heading {
        position: absolute;
        bottom: 50px; 
        left: 50%;
        transform: translateX(-50%);
        color: white;

        padding: 10px;
        border-radius: 8px;
        text-align: center;
    }

    .container {
        max-width: 1200px;
        margin: 0 auto;
    }

    .text-center {
        text-align: center;
    }

    .text-secondary {
        color: #6c757d;
    }

    .section-fourth {
        padding: 2rem 0;
    }

    .find_Properties .row {
        display: flex;
        flex-wrap: wrap;
    }

    .find_Properties .col-4, .find_Properties .col-8 {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .find_Properties .col-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .find_Properties .col-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

      
  .why-chose-us-cards-section {
    /* max-width: 1400px; */
    width: 100%;
    /* background-image: url(/public/Images/download-image.png); */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    height: max-content;
    margin: 0 auto;
    /* .choose-left-side{
    
      display: flex;
      gap: 0px; */
      .choose-card{
        width:30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 15px 20px;
 
      /* border-left: none !important;
      border-bottom: none !important;
      border-top: none !important; */
        .resion-heading{
          margin: 20px 0 16px 0;
          font-size: 16px;
          cursor: pointer;
          font-weight: 600;
         
          color: #2b2b2b;
        }

        .card-image-post{
          position: relative; 
          overflow: hidden; 
          border-radius: 12px;
          width: 100%;
        }
        .primary-btn{
         font-size: 16px;
        }
        .card-image-post img {
          width: 100%;
          height: 100%;
          transition: transform 0.5s; 
        }
        .card-image-post .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          background-color:rgba(0, 0, 0, 0.6); ;
          top: 0;
          left: 0;
          z-index: 2;
          opacity: 0;
          transition: transform 0.4s;
        }
        
       .post-logo-image{
    transition: all 0.3s ease-in-out;
        padding-top: 5px;
        height: 76px;
       }
        
       
        .resion-heading:hover{
          color: #7a1ea3;
          transition: all 0.3s ease-in-out;
        }
        .card-why-paragraph{
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          margin: 16px 0 20px 0;
          flex-grow: 1;
          color: #585981;
          text-align: center;
        }
        .see-all-listing-btn{
          position: relative;
          font-size: 14px;
          font-weight: 600;
          color: #2b2b2b;
          padding-right: 18px;
          cursor: pointer;
          display: inline;
          position: relative;
         
      
          svg {
            position: absolute;
            top: 10px;
            transform: translateY(-50%);
            right: -1px; /* Adjust as needed */
            transition: right 0.3s ease; /* Transition for smooth movement */
          }
        }
       
      }
      .see-all-listing-btn:hover {
        svg {
          right: -6px;
        }
      }
      .choose-card:hover{
        .post-logo-image{
          border: 1px solid rgb(44, 38, 38)(180, 21, 21);
          padding-top: 00px;
          transition: all 0.3s ease-in-out;
         }
          
      }
      .choose-card:hover .card-image-post img {
        transform: scale(1.11);
      }
      .choose-card:hover .card-image-post .overlay{
        transition: all 0.3s ease-in-out;
        opacity: 1;
      }
        
    
    /* .choose-right-side{
      width: 100%;
      height: 100%;
      background-image: url(/public/Images/handimage.png);
     overflow: hidden;
      background-size: cover;
    background-repeat: no-repeat;
    text-align: end;
    } */
  }



  .card {
    margin-bottom: 20px;
    position: relative;
    border-radius: 8px !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.card:hover {
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    /* transform: scale(0.1);  */
}

.card-body {
    padding-bottom: 60px; 
}

.profile-date-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
}

.profile-info {
    display: flex;
    align-items: center;
}

.profile-info img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.date {
    margin-left: auto;
}


.card-img-top{
  border-radius: 31px !important;
    padding: 10px !important;
}

}
