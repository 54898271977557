.under-buy-dropbox {
    background: #fff !important;
    box-shadow: 0px 2px 5px rgb(92, 88, 88);
    overflow: hidden;
    border-radius: 8px;
    
    width: fit-content !important;
    font-family: 'Poppins', sans-serif !important;
    color: #2b2b2b;
    .drop-header-div {
      display: flex;
      width: auto;
      background-color: #f5f1f1bd;
      padding:20px 35px 20px 35px;
      flex-direction: column;
      gap: 12px;
      .header-item {
        font-size: 15px;
        font-weight: 500;
        text-wrap: nowrap;
        display:flex;
        align-items:center;
        gap: 10px;
        text-transform: uppercase;
        color: #555151;
        .tag{
            background-color: #7A1EA3;
            font-size: 9px !important;
           display: flex;
           align-items: center;
           border-radius: 2px;

           justify-content: center;
            padding: 2px 4px 1px 4px;
            color: #fff;
        }
      }
      .header-item:hover{
        color: #000;
      }
    }

    .drop-header-div2 {
        display: flex;
        width: auto;
        /* background-color: #fdf7f7ec; */
        padding:20px 35px 20px 35px;
        flex-direction: column;
        gap: 12px;
     
        .header-item {
          font-size: 15px;
          font-weight: 500;
          text-wrap: nowrap;
          display:flex;
          align-items:center;
          gap: 10px;
          
          color: #555151;
          .tag{
              background-color: #7A1EA3;
              font-size: 9px !important;
             display: flex;
             align-items: center;
             border-radius: 2px;
  
             justify-content: center;
              padding: 2px 4px 1px 4px;
              color: #fff;
          }
        }
        .header-item:hover{
          color: #000;
        }
      }


    .under-content{
        display: flex;
        flex-direction: column;
        gap: 7px;
    }
.property-div{
    width:auto;
  
    padding:20px 25px;
    .gray-header{
        color: #555151;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 10px;
        text-transform: uppercase;
        text-wrap: nowrap;
    }
    .property-name{
        font-size: 14.7px !important;
        font-weight: 500 !important;
        color: #2b2b2b;
        text-wrap: nowrap;
      
        display:flex;
        align-items:center;
        gap: 10px;
    }
     .property-name .tag{
        background-color: #7A1EA3;
            font-size: 9px !important;
           display: flex;
           color: #fff;
           line-height: 17px;
           padding: 0px 6px;
           align-items: center;
           border-radius: 2px;
    }

}
.popular-div{
    width: auto;

    padding:20px 25px;
  
    .gray-header{
        color: #555151;
        font-size: 15px;
        text-wrap: nowrap;
        font-weight: 400;
        margin-bottom: 16px;
        text-transform: uppercase;
    }
    .popular-name{
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #2b2b2b;
        text-wrap: nowrap;
    }
}
.explore-div{
    width:auto;
 
    padding:20px 25px;
    .gray-header{
        color: #555151;
        text-wrap: nowrap;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 16px;
        text-transform: uppercase;
    }
    .explore-name{
        font-size: 14px !important;
        font-weight: 500 !important;
        text-wrap: nowrap;
        color: #2b2b2b;
    }
}

    .insights-card{
        background-color: #ebdcf1bb;
        width: auto;
        border-radius: 12px;
        padding: 20px 25px;
        .insights-image{
            img{
                width: 45px;
            }
        }
        .intro-insight{
            span{
                color: #7A1EA3;
                font-size: 10px;
                font-weight: 700;
                padding: 0;
                margin: 0;

            }
            h3{
                padding: 0;
                margin: 0;
                font-size: 20px;
                font-weight: 700;
                color: #2b2b2b;

            }
        }
        .up-right-arrow{
        svg{
            color: #7A1EA3;
        }
        }
        .right-icon{
            svg{
                color: #7A1EA3;
            }
        }
        .content-service{
            font-size: 14px !important;
            font-weight: 400 !important;

        }

    }

    
  }