.slider-section {
  position: relative;
  width: 100%;
  font-family: "Poppins", sans-serif !important;
  button:disabled {
    display: none;
  }

  .p-carousel-items-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .p-carousel .p-carousel-content .p-carousel-prev {
    position: absolute;
    left: -30px;
    z-index: 1;
    background-color: white;
    color: #2b2b2b;
    border-radius: 50%;
    transition: all 0.2s ease;
    width: 45px;
    height: 45px;
    padding: 0px;
    margin: 0 5px;
    box-shadow: 0px 4px 10px 0px rgb(150, 148, 148);
  }
  .p-carousel .p-carousel-content .p-carousel-next {
    position: absolute;
    right: -30px;
    z-index: 1;
    background-color: white;
    color: #2b2b2b;
    border-radius: 50%;
    transition: all 0.2s ease;
    width: 45px;
    height: 45px;
    padding: 0px;
    margin: 0px 5px;
    box-shadow: 0px 4px 10px 0px rgb(150, 148, 148);
  }
  .p-carousel .p-carousel-content .p-carousel-prev:hover {
    background-color: #7a1ea3;
    color: #ffffff !important;
  }
  .p-carousel .p-carousel-content .p-carousel-prev:hover {
    padding-right: 6px;
    transition: all 0.5s ease;
  }
  .p-carousel .p-carousel-content .p-carousel-next:hover {
    color: #ffffff !important;
    background-color: #7a1ea3;
  }
  .p-carousel .p-carousel-content .p-carousel-next:hover {
    color: #ffffff !important;
    background-color: #7a1ea3;
    padding-left: 6px;
    transition: all 0.5s ease;
  }
  .slider-one-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    font-family: "Poppins", sans-serif !important;
    cursor: pointer;
    gap: 8px;

    position: relative;
    .simple-paragraph {
      color: #2b2b2b !important;
      font-size: 16px;
      text-align: center;

      font-weight: 500;
    }
  }

  .circle-image {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    height: 110px;
    width: 110px;
  }

  .circle-image img {
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
  }
  .circle-image .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    transition: transform 0.4s;
  }

  .slider-one-card:hover .circle-image img {
    transform: scale(1.13);
  }

  .slider-one-card:hover {
    .simple-paragraph {
      color: #7a1ea3;
    }
  }
  .slider-one-card:hover .circle-image .overlay {
    opacity: 1;
  }

  .slider-trusted-companies-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    font-family: "Poppins", sans-serif !important;
    cursor: pointer;
    gap: 8px;
  }
  .category-slider-card {
    display: flex;
    flex-direction: column;
    /* margin: 0 40px 0 0; */
    width: 95%;
    gap: 24px;
    .rounded-image {
      img {
        border-radius: 12px;
      }
    }
    .gray-normal-btn {
      button {
        background: none;
        border: none;
        outline: none;
        color: #8f90a6;
        font-size: 19px;
        font-weight: 300;
        gap: 16px;
      }
    }
  }
  .features-collection-slider-card {
    /* margin: 0 30px 0 0; */
    width: 91%;
    font-family: "Poppins", sans-serif !important;
    cursor: pointer;
    .rounded-image-div {
      position: relative;
      width: 100%;
      height: 190px;
      border-radius: 13px;
      overflow: hidden;
      color: #fff !important;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 16px;
      display: flex;
      align-items: end;

      .content-div {
        position: relative;
        z-index: 1; /* Ensure content is above the overlay */
        color: #ffffff;

        .h5-heading {
          h5 {
            line-height: 24px !important;
            padding: 0 !important;
            margin: 0 2px !important;
            font-weight: 600 !important;
          }
        }
        .explore-btn {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .rounded-image-div::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6); /* Black with 50% opacity */
      opacity: 0; /* Initially hidden */
      transition: opacity 0.3s; /* Smooth transition for opacity */
    }

    .rounded-image-div:hover::before {
      opacity: 1; /* Show the overlay on hover */
    }
  }
  .primeProject-slider-card {
    /* margin: 0 30px 0 0; */
    font-family: "Poppins", sans-serif !important;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden !important;
    height: 100%;
    width: 95.7%;
    transition: all 0.3s ease 0s;

    .rounded-image {
      overflow: hidden !important;
      transition: transform 0.5s ease-in-out 0.2s;
      img {
        transition: all 0.35s ease-in;
      }
    }
    .h5-heading {
      h5 {
        line-height: 24px !important;
        padding: 0 !important;

        font-weight: 500 !important;
      }
    }
    .group-name {
      color: #585981;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 22px !important;
    }
    .apartment,
    .location {
      font-size: 14px;
      color: #8f90a6;
      font-weight: 400;
      line-height: 19px;
    }
    .price {
      font-size: 16px;
      font-weight: 500;
      color: #2b2b2b;
      line-height: 22px;
    }
    /* .view-detail-btn{
      background-color: #7A1EA3;
      color: #fff;
    width: 130px;
      font-size: 14px;
     transition: all .3s ease-in-out;
      font-weight: 400;
    position: absolute;
    display: block;
    top: 400px;
      text-align: center;
      padding: 8px 16px;
      border-radius: 25px;
    } */
  }
  .primeProject-slider-card:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 12px 0px;
    transition: all 0.3s ease 0s;
  }
 
  .primeProject-slider-card:hover .rounded-image img {
    transform: scale(1.1);
  }

  /* for two cards */
  .popularOwner-slider-card {
    /* margin: 0 30px 0 0; */
    font-family: "Poppins", sans-serif !important;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden !important;
    position: relative;
    height: 100%;
    width: 97%;
    transition: all 0.3s ease 0s;

    .rounded-image {
      overflow: hidden !important;
      transition: transform 0.5s ease-in-out 0.2s;
      img {
        transition: all 0.35s ease-in;
      }
    }
    .h5-heading {
      h5 {
        line-height: 24px !important;
        padding: 0 !important;
        width: 210px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500 !important;
      }
    }
    .group-name {
      color: #585981;
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 22px !important;
    }
    .apartment,
    .location {
      font-size: 14px;
      color: #8f90a6;
      font-weight: 400;
      line-height: 19px;
    }
    .price {
      font-size: 16px;
      font-weight: 500;
      color: #2b2b2b;
      line-height: 22px;
    }
    .view-detail-btn {
      background-color: #7a1ea3;
      color: #fff;
      width: 130px;
      font-size: 14px;
      transition: all 0.3s ease-in-out;
      font-weight: 400;
      position: absolute;
      display: block;
      top: 400px;
      text-align: center;
      padding: 8px 16px;
      border-radius: 25px;
    }
  }
  .popularOwner-slider-card:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 12px 0px;
    transition: all 0.3s ease 0s;
  }
  .popularOwner-slider-card:hover .view-detail-btn {
    transition: all 0.3s ease-in-out 0.1s;
    top: 310px;
  }
  .popularOwner-slider-card:hover .rounded-image img {
    transform: scale(1.1);
  }

  .spotlight-container-slider-card {
    display: flex;
    font-family: "Poppins", sans-serif !important;
    flex-wrap: wrap;
    padding: 8px;
    border-radius: 20px;
    background-image: linear-gradient(
      to left bottom,
      rgba(255, 234, 203, 1),
      rgba(255, 232, 253, 1)
    );

    .left-spotlight {
      width: 25%;
      background: transparent;
      color: #000 !important;
      padding: 32px;
      .signature-brand {
        display: flex;
        align-items: center;
        color: #000 !important;
        gap: 16px;
        margin-bottom: 30px;
        .brand-name {
          h6 {
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        img {
          border-radius: 10px;
        }
        a {
          color: #7a1ea3;
          padding-bottom: 5px !important;

          font-size: 14px;
        }
      }
      .brand-name {
        h5 {
          font-size: 18px !important;
          color: #000 !important;
          p {
            font-size: 14px !important;
            color: #000 !important;
          }
        }
      }
      .price-location {
        margin: 25px 0;
        p {
          font-size: 14px;
        }
        h5 {
          font-size: 18px !important;
          font-weight: 500 !important;
        }
      }
    }
    .right-spotlight {
      width: 75%;
      height: 385px;
      border-radius: 16px;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      padding: 30px 16px;
      display: flex;

      align-items: end;

      .spotlight-image-content {
        visibility: hidden;
        color: #fff !important;
        width: 100%;
        display: flex;
        align-items: end;
        justify-content: space-between;
        brand-image-name {
          h6 {
            font-size: 14px;
            font-weight: 600;
            line-height: 23px;
            width: 190px !important;
          }
        }
        .location-data {
          font-size: 12px;
          color: gray !important;
        }
        .apartment {
          font-size: 14px;
          font-weight: 400;
        }
        .price-value {
          font-size: 15px;
          font-weight: 500;
          color: #fff;
        }
      }
      .primary-btn {
        display: inline-block !important;
        text-align: center;
        width: 90px !important;
        padding: 8px 16px !important;
        font-size: 12px;
        font-weight: 400;
        border-radius: 12px !important;
      }

      .carousel-indicators {
        position: absolute;
        display: none;
        bottom: 10px; /* Adjust the position as needed */
        left: 50%;
        transform: translateX(-50%);
      }

      .indicator {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #888; /* Default color */
        border-radius: 50%;
        margin: 0 5px; /* Adjust spacing between indicators */
      }

      .indicator.active {
        background-color: #000; /* Active color */
      }
    }
  }

  .Prestigious-slider-card {
    margin: 0 30px 0 0;
    font-family: "Poppins", sans-serif !important;
    .rounded-image-div {
      position: relative;
      width: 100%;
      height: 350px;
      border-radius: 13px;
      overflow: hidden;
      color: #fff !important;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 16px;
      display: flex;
      align-items: end;

      .content-div {
        display: flex !important;
        width: 100%;
        justify-content: space-between !important;
        position: relative;
        z-index: 1; /* Ensure content is above the overlay */
        color: #ffffff;

        .h5-heading {
          h5 {
            line-height: 24px !important;
            padding: 0 !important;
            margin: 0 2px !important;
            font-weight: 700 !important;
          }
        }
        .group-name {
          font-size: 16px !important;
          font-weight: 400 !important;
          line-height: 22px !important;
        }
        .apartment,
        .location {
          font-size: 14px;

          font-weight: 400;
          line-height: 19px;
        }
        .price {
          font-size: 16px;
          font-weight: 500;

          line-height: 22px;
        }
      }
    }

    .rounded-image-div::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6); /* Black with 50% opacity */
      opacity: 0; /* Initially hidden */
      transition: opacity 0.3s; /* Smooth transition for opacity */
    }

    .rounded-image-div:hover::before {
      opacity: 1; /* Show the overlay on hover */
    }
  }

  .top-selections-slider-card {
    /* margin: 0 30px 0 0; */
    font-family: "Poppins", sans-serif !important;
    .rounded-image-div {
      position: relative;
      width: 95.3%;
      height: 230px;
      border-radius: 13px;
      overflow: hidden;
      color: #fff !important;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 16px;
      margin-bottom: 20px;

      .content-div {
        display: flex !important;

        flex-direction: column;
        height: 100%;
        justify-content: space-between !important;
        position: relative;
        z-index: 1; /* Ensure content is above the overlay */
        color: #ffffff;
        .rera-tag {
          align-self: flex-start;
          background-color: #06afb0;
          display: block !important;
          padding: 6px 10px;
          border-radius: 8px;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }

    .rounded-image-div::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6); /* Black with 50% opacity */
      opacity: 0; /* Initially hidden */
      transition: opacity 0.3s; /* Smooth transition for opacity */
    }

    .rounded-image-div:hover::before {
      opacity: 1; /* Show the overlay on hover */
    }
    .bottom-content-div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .h5-heading {
        h5 {
          line-height: 24px !important;
          padding: 0 !important;
          margin: 0;
          font-weight: 500 !important;
        }
      }

      .apartment,
      .location {
        font-size: 14px;
        color: #8f90a6;
        font-weight: 400;
        line-height: 19px;
      }
      .price {
        font-size: 16px;
        font-weight: 500;

        line-height: 22px;
      }
    }
  }
  .premier-property-slider-card {
    margin: 0 30px 0 0;
    font-family: "Poppins", sans-serif !important;
    .rounded-image-div {
      width: 100%;
      height: 230px;
      border-radius: 13px;

      background-size: cover;
    }
  }
  .Hot-Picks-slider-card {
    display: flex;
    width: 88%;
    overflow: hidden;
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #dee2e6;
    border-radius: 100px;
    .rounded-image {
      /* width: 40%; */
      height: 65px !important;
      width: 65px;
      
      img {
        border-radius: 50%;
        width: 65px;
        height: 65px
      }
    }
    .content-div-hot {
      display: flex;
      flex-direction: column;

      .h5-heading {
        h5 {
          line-height: 24px !important;
          padding: 0 !important;
          font-size: 17px;
          margin: 0 2px !important;
          width: 130px;
          font-weight: 600 !important;
        flex-grow: 1;
        }
      }
      .group-name {
        color: #585981;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 22px !important;
        flex-grow: 1;
      }

      .price {
        font-size: 20px;
        font-weight: 600;
        color: #2b2b2b;
        line-height: 22px;
        white-space: nowrap;
        width: 160px;
        overflow: hidden;
      }
    }
  }
  .Hot-Picks-slider-card2 {
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    border-radius: 10px;
    background: #fff;
    width: 86%;
    .rounded-image2 {
      width: 100%;
      /* height: 91px !important; */
      img {
        border-radius: 8px 8px 0px 0px;
      }
    }
    .content-div-hot2 {
      padding: 20px 16px;

      .h5-heading2 {
        h5 {
          line-height: 24px !important;
          padding: 0 !important;
          font-size: 18px;
          margin: 0 2px !important;
          font-weight: 700 !important;
          width: 140px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .group-name2 {
        color: #585981;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 22px !important;
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .price {
        font-size: 20px;
        font-weight: 600;
        color: #2b2b2b;
        line-height: 22px;
        white-space: nowrap;
        width: 160px;
        overflow: hidden;
      }
    }
  }

  .testomonial-slider-card {
    width: 96%;
    /* border-radius: 12px; */
    height: 230px;
    padding:12px  16px;
    /* background: #fff; */
    gap: 5px;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    color: #2B2B2B !important;
    .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
      color: orange;
      width: 10px;
      height: 10px;
      margin-top: -5px;
    }
   
    .testomonial-text {
      font-size: 15px;
      line-height: 24px;
      margin-top: 10px;
    text-align: justify;
      flex-grow: 1;
      font-weight: 350;
    }
.client-image{
  width: 80px;
  height: 80px;
  overflow: hidden;
  padding: 5px;
  border: 3px solid #dee2e6;;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  img{
    width: 100%;
    border-radius: 50%;
    height: 100%;
  }
}
    .client-name {
      font-size: 18px;
      font-weight: 600;
      margin-top: 2px;
    }
    .client-position {
      font-size: 15px;
      font-weight: 400;
      color: #585981;
    }
  }
  /* .testomonial-slider-card:hover{
    background: linear-gradient(to right, #330856, #6B0D7E);
    color: #fff !important;
  } */

  .top-agencies-slider-card {
    display: flex;
    flex-direction: column;
    width: 91.5%;
    background-color: #fff;
    border-radius: 8px;
  padding: 10px 16px;
    flex-grow: 1;
      
      .heart-icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(#9bc90d 0, #79a70a 100%);
        border-radius: 50%;
        svg {
          color: #fff;
        }
      }
      .featured-box {
        color: #2B2B2B;
        background-color:#FFC72C;
        font-size: 14px;
        padding: 7px 18px;
        font-weight: 500;
    /* align-self: flex-start; */
        display: flex;
        align-items: center;
        line-height: 12px;
        border-radius: 5px;
      }
      .agency-image {
        width: 125px;
        margin: 0px auto;
        overflow: hidden;
        height: 125px;
        img {
          width: 100%;
          height: 100%;
        
        }
      }
     .agency-listing{
      font-size: 16px;
      color: #585981;
      font-weight: 400;
     }
      .agency-name {
        font-size: 19px;
        flex-grow: 1;
        font-weight: 600;
        color: #2b2b2b;
        line-height: 25px;
      }
  
    
   
  }
  .top-agents-slider-card {
    display: flex;
    flex-direction: column;
    width: 93.5%;
    background-color: #fff;
    /* background-color: red; */
    border-radius: 12px;
    .contact-agent-btn {
      font-weight: 400;
      color: #7a1ea3;
  
      margin-left: auto;
      margin-right: auto;
      border: 1px solid #7a1ea3;
      /* background-color: #7b1ea318; */
      border-radius: 6px;
      margin-top: 4px;
      padding: 3px 25px;
      cursor: pointer;
    }
    .contact-agent-btn:hover {
      background-color: #7a1ea3;
      color: #fff;
    }

    .top-part-card {
      border-bottom: 1px solid #dee2e6;
      padding: 10px 12px 10px 12px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .heart-icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #dee2e6;
        border-radius: 50%;
        svg {
          color: crimson;
        }
      }
      .property-box {
        color: #7a1ea3;
        background-color: #7b1ea318;
        font-size: 12px;
        padding: 6px 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        line-height: 15px;
        border-radius: 18px;
      }
      .agency-image {
        border: 3px solid #dee2e6;
        border-radius: 50%;
        width: 80px;
        padding: 6px;
        margin:0px auto 4px auto;
        overflow: hidden;
        height: 80px;
        img {
          width: 100%;
          height: 100%;

          border-radius: 50%;
        }
      }
   
      .agency-name {
        font-size: 17px;
        font-weight: 600;
        color: #2b2b2b;
        line-height: 25px;
      }
      .review-star {
        display: flex;
        align-items: center;
        font-size: 13px;
        justify-content: center;
        gap: 5px;
        font-weight: 500;
        margin: 0px 0;
        color: #585981;
        .star-yellow{
          margin-top: -3px;
        }
      }
    }
    .footer-part-card {
      padding: 4px 12px;
      .location-agency {
        color: #585981;
        font-size: 13px;
        display: flex;
        align-items: center;
        gap: 3px;
      }
      .social-icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #dee2e6;
        color: #585981;
        font-size: 15px;
        cursor: pointer;
        border-radius: 50%;
      }
    }
  }
  @media (max-width: 480px) {
    /* .p-carousel .p-carousel-content .p-carousel-next{
      right: -10px;
    } */

    .p-carousel-items-container {
      display: flex;
      flex-direction: row;
      gap: 0px !important;
    }
    .slider-one-card {
      width: 100% !important;
    }
    .top-agencies-slider-card {
      width: 100% !important;
    }
    .top-agents-slider-card {
      width: 100% !important;
    }
    .features-collection-slider-card {
      width: 100% !important;
    }
    .popularOwner-slider-card {
      /* margin: 0 30px 0 0; */
      font-family: "Poppins", sans-serif !important;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden !important;

      position: relative;
      /* height: 100%; */
      width: 100% !important;
      transition: all 0.3s ease 0s;

      .rounded-image {
        overflow: hidden !important;
        transition: transform 0.5s ease-in-out 0.2s;
        img {
          transition: all 0.35s ease-in;
        }
      }
      .h5-heading {
        h5 {
          line-height: 24px !important;
          padding: 0 !important;
          font-size: 16px !important;
          font-weight: 500 !important;
          width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .group-name {
        color: #585981;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 10px !important;
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .apartment,
      .location {
        font-size: 12px;
        color: #8f90a6;
        font-weight: 400;
        line-height: 19px;
      }
      .price {
        font-size: 14px;
        font-weight: 500;
        color: #2b2b2b;
        text-align: center;
        line-height: 22px;
      }
      .view-detail-btn {
        background-color: #7a1ea3;
        color: #fff;
        width: 120px;
        font-size: 12px;
        transition: all 0.3s ease-in-out;
        font-weight: 400;
        position: absolute !important;
        display: block;
        top: 440px;
        text-align: center;
        padding: 8px 12px;
        border-radius: 25px;
      }
    }
    .popularOwner-slider-card:hover {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 12px 0px;
      transition: all 0.3s ease 0s;
    }
    .popularOwner-slider-card:hover .view-detail-btn {
      transition: all 0.3s ease-in-out 0s;
      top: 360px;
      right: 4%;
    }
    .popularOwner-slider-card:hover .rounded-image img {
      transform: scale(1.1);
    }

    .spotlight-container-slider-card {
      display: flex;
      font-family: "Poppins", sans-serif !important;
      flex-wrap: wrap;
      padding: 0px !important;
      border-radius: 20px;
      background-image: linear-gradient(
        to left bottom,
        rgba(255, 234, 203, 1),
        rgba(255, 232, 253, 1)
      );

      .left-spotlight {
        /* width: 25%; */
        display: none !important;
        background: transparent;
      }
      .right-spotlight {
        width: 100% !important;
        height: 350px;
        border-radius: 8px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        padding: 30px 16px;
        display: flex;

        align-items: end;

        .spotlight-image-content {
          visibility: visible !important;
          color: #fff !important;
          width: 100%;
          display: flex;
          align-items: end;
          justify-content: space-between;
          brand-image-name {
            h6 {
              font-size: 15px !important;
              font-weight: 550 !important;
              line-height: 23px;
              width: 190px !important;
            }
          }
          .location-data {
            font-size: 14px;
            color: #cbcbd6 !important;
          }
          .apartment {
            font-size: 14px;
            font-weight: 400;
          }
          .price-value {
            font-size: 15px !important;
            font-weight: 500 !important;
            color: #fff;
          }
        }
        .primary-btn {
          display: inline-block !important;
          text-align: center;
          width: 90px !important;
          padding: 0px 10px !important;
          font-size: 14px;
          font-weight: 400;
          border-radius: 12px !important;
        }

        .carousel-indicators {
          position: absolute;
          bottom: -10px !important; /* Adjust the position as needed */
          left: 41%;

          display: block !important;
          transform: translateX(-50%);
        }

        .indicator {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #888; /* Default color */
          border-radius: 50%;
          margin: 0 5px; /* Adjust spacing between indicators */
        }

        .indicator.active {
          background-color: #fff; /* Active color */
        }
      }
    }

    .slider-trusted-companies-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100% !important;
      font-family: "Poppins", sans-serif !important;
      cursor: pointer;
      gap: 8px;
    }
    .Hot-Picks-slider-card2 {
      display: flex;
      flex-direction: column;
      /* border: 1px solid red; */
      border-radius: 10px;
      background: #fff;
      width: 100%;
    }

    .testomonial-slider-card {
      width: 100% !important;
      border: 1px solid #dee2e6;
      border-radius: 12px;
      height: 260px;
      .client-image{
      height: 80px;
        width: 80px;
      }

    }
    .category-slider-card {
      width: 100%;
      .gray-normal-btn {
        button {
          background: none;
          border: none;
          outline: none;
          color: #8f90a6;
          font-size: 16px;
          font-weight: 300;
          gap: 16px;
        }
      }
    }
    .primeProject-slider-card {
      width: 100% !important;
      .rounded-image {
        img {
          margin-bottom: 15px;
        }
      }
      .h5-heading {
        h5 {
          font-size: 16px;
        }
      }
      .group-name {
        font-size: 14px !important;
      }
      .apartment,
      .location {
        font-size: 12px;
      }
      .price {
        font-size: 14px;
      }
    }
    .Prestigious-slider-card {
      width: 100% !important;
      .rounded-image-div {
        width: 100%;
        height: 300px;

        .content-div {
          display: flex !important;
          width: 100%;
          justify-content: space-between !important;
          flex-wrap: wrap;
          position: relative;
          z-index: 1; /* Ensure content is above the overlay */
          color: #ffffff;

          .h5-heading {
            h5 {
              line-height: 16px !important;
              padding: 2px 0;
            }
          }
          .group-name {
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 22px !important;
          }
          .apartment,
          .location {
            font-size: 12px;

            font-weight: 400;
            line-height: 19px;
          }
          .price {
            font-size: 14px !important;
            font-weight: 500;

            line-height: 22px;
          }
        }
      }
    }
    .top-selections-slider-card {
      width: 100% !important;
      .rounded-image-div {
        position: relative;
        width: 100%;
        height: 200px;
        border-radius: 13px;
        overflow: hidden;
        color: #fff !important;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 16px;
        margin-bottom: 20px;

        .content-div {
          display: flex !important;

          flex-direction: column;
          height: 100%;
          justify-content: space-between !important;
          position: relative;
          font-size: 14px !important;
          z-index: 1; /* Ensure content is above the overlay */
          color: #ffffff;
          .rera-tag {
            align-self: flex-start;
            background-color: #06afb0;
            display: block !important;
            padding: 6px 10px;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }

      .bottom-content-div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .h5-heading {
          h5 {
            line-height: 24px !important;
            font-size: 16px !important;
            padding: 0 !important;
            margin: 0;
            font-weight: 700 !important;
          }
        }

        .apartment,
        .location {
          font-size: 12px;
          color: #8f90a6;
          font-weight: 400;
          line-height: 19px;
        }
        .price {
          font-size: 14px;
          font-weight: 500;

          line-height: 22px;
        }
      }
    }
    .premier-property-slider-card {
      width: 100% !important;
    }
    .Hot-Picks-slider-card {
      width: 100% !important;
      display: flex;
      /* justify-content: space-between; */
      gap: 32px !important;
     
      .content-div-hot {
        padding: 0px;
        .h5-heading {
          h5 {
            line-height: 24px !important;
            padding: 0 !important;
            margin: 0 2px !important;
            font-weight: 700 !important;
            font-size: 16px;
            width: 160px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .group-name {
          color: #585981;
          font-size: 14px !important;
          font-weight: 400 !important;
          line-height: 22px !important;
          width: 160px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .price {
          font-size: 16px;
          font-weight: 600;
          color: #2b2b2b;
          line-height: 22px;
          white-space: nowrap;
          width: 160px;
          overflow: hidden;
        }
      }
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .p-carousel-items-container {
      display: flex;
      flex-direction: row;
      gap: 0px !important;
    }
    .slider-one-card {
      width: 100% !important;
    }

    .spotlight-container-slider-card {
      display: flex;
      font-family: "Poppins", sans-serif !important;
      flex-wrap: wrap;
      padding: 0px !important;
      border-radius: 20px;
      background-image: linear-gradient(
        to left bottom,
        rgba(255, 234, 203, 1),
        rgba(255, 232, 253, 1)
      );
      transition: all 0s ease-in-out;
      .left-spotlight {
        /* width: 25%; */
        display: none !important;
      }
      .right-spotlight {
        width: 100% !important;
        height: 350px;
        border-radius: 8px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        padding: 30px 16px;
        display: flex;

        align-items: end;

        .spotlight-image-content {
          visibility: visible !important;
          color: #fff !important;
          width: 100%;
          display: flex;
          align-items: end;
          justify-content: space-between;
          brand-image-name {
            h6 {
              font-size: 16px !important;
              font-weight: 550 !important;
              line-height: 23px;
              width: 190px !important;
            }
          }
          .location-data {
            font-size: 16px;
            color: #cbcbd6 !important;
          }
          .apartment {
            font-size: 16px;
            font-weight: 400;
          }
          .price-value {
            font-size: 17px !important;
            font-weight: 500 !important;
            color: #fff;
          }
        }
        .primary-btn {
          display: inline-block !important;
          text-align: center;
          width: 150px !important;
          padding: 10px 10px !important;
          font-size: 14px;
          font-weight: 400;
          border-radius: 12px !important;
        }

        .carousel-indicators {
          position: absolute;
          bottom: -10px !important; /* Adjust the position as needed */
          left: 41%;

          display: block !important;
          transform: translateX(-50%);
        }

        .indicator {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #888; /* Default color */
          border-radius: 50%;
          margin: 0 5px; /* Adjust spacing between indicators */
        }

        .indicator.active {
          background-color: #fff; /* Active color */
        }
      }
    }

    .features-collection-slider-card {
      width: 100% !important;
      .rounded-image-div {
        height: 220px !important;
      }
    }

    .category-slider-card {
      width: 100%;
      .gray-normal-btn {
        button {
          background: none;
          border: none;
          outline: none;
          color: #8f90a6;
          font-size: 16px;
          font-weight: 300;
          gap: 16px;
        }
      }
    }
    .primeProject-slider-card {
      width: 100% !important;
      .rounded-image {
        img {
          margin-bottom: 15px;
        }
      }
      .h5-heading {
        h5 {
          font-size: 16px;
        }
      }
      .group-name {
        font-size: 14px !important;
      }
      .apartment,
      .location {
        font-size: 12px;
      }
      .price {
        font-size: 14px;
      }
    }
    .Prestigious-slider-card {
      width: 100% !important;
      .rounded-image-div {
        width: 100%;
        height: 300px;

        .content-div {
          display: flex !important;
          width: 100%;
          justify-content: space-between !important;
          flex-wrap: wrap;
          position: relative;
          z-index: 1; /* Ensure content is above the overlay */
          color: #ffffff;

          .h5-heading {
            h5 {
              line-height: 16px !important;
              padding: 2px 0;
            }
          }
          .group-name {
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 22px !important;
          }
          .apartment,
          .location {
            font-size: 12px;

            font-weight: 400;
            line-height: 19px;
          }
          .price {
            font-size: 14px !important;
            font-weight: 500;

            line-height: 22px;
          }
        }
      }
    }
    .top-selections-slider-card {
      width: 100% !important;
      .rounded-image-div {
        position: relative;
        width: 100%;
        height: 200px;
        border-radius: 13px;
        overflow: hidden;
        color: #fff !important;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 16px;
        margin-bottom: 20px;

        .content-div {
          display: flex !important;

          flex-direction: column;
          height: 100%;
          justify-content: space-between !important;
          position: relative;
          font-size: 14px !important;
          z-index: 1; /* Ensure content is above the overlay */
          color: #ffffff;
          .rera-tag {
            align-self: flex-start;
            background-color: #06afb0;
            display: block !important;
            padding: 6px 10px;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }

      .bottom-content-div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .h5-heading {
          h5 {
            line-height: 24px !important;
            font-size: 16px !important;
            padding: 0 !important;
            margin: 0;
            font-weight: 700 !important;
          }
        }

        .apartment,
        .location {
          font-size: 12px;
          color: #8f90a6;
          font-weight: 400;
          line-height: 19px;
        }
        .price {
          font-size: 14px;
          font-weight: 500;

          line-height: 22px;
        }
      }
    }
    .premier-property-slider-card {
      width: 100% !important;
    }
    .Hot-Picks-slider-card {
      width: 100% !important;
      display: flex;
      /* justify-content: space-between; */
      gap: 32px !important;
      
      
    }
  }

  /*  this media query only view detail button card  */
  @media (min-width: 481px) and (max-width: 514px) {
    .popularOwner-slider-card {
      /* margin: 0 30px 0 0; */
      font-family: "Poppins", sans-serif !important;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden !important;
      /* height: 100%; */
      position: relative;
      width: 100% !important;
      transition: all 0.3s ease 0s;

      .rounded-image {
        overflow: hidden !important;
        transition: transform 0.5s ease-in-out 0.2s;
        img {
          transition: all 0.35s ease-in;
        }
      }
      .h5-heading {
        h5 {
          line-height: 24px !important;
          padding: 0 !important;
          font-size: 17px !important;
          font-weight: 550 !important;
          width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .group-name {
        color: #585981;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 10px !important;
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .apartment,
      .location {
        font-size: 12px;
        color: #8f90a6;
        font-weight: 400;
        line-height: 19px;
      }
      .price {
        font-size: 14px;
        font-weight: 500;
        color: #2b2b2b;
        text-align: center;
        line-height: 22px;
      }
      .view-detail-btn {
        background-color: #7a1ea3;
        color: #fff;
        width: 120px;
        font-size: 12px;
        transition: all 0.3s ease-in-out;
        font-weight: 400;
        position: absolute;
        display: block;
        top: 420px;
        text-align: center;
        padding: 8px 12px;
        border-radius: 25px;
      }
    }
    .popularOwner-slider-card:hover {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 12px 0px;
      transition: all 0.3s ease 0s;
    }
    .popularOwner-slider-card:hover .view-detail-btn {
      transition: all 0.3s ease-in-out 0.1s;
      top: 360px;
      right: 4%;
    }
    .popularOwner-slider-card:hover .rounded-image img {
      transform: scale(1.1);
    }
  }
  /*  this media query only view detail button card  */
  @media (min-width: 515px) and (max-width: 767px) {
    .popularOwner-slider-card {
      /* margin: 0 30px 0 0; */
      font-family: "Poppins", sans-serif !important;
      border-radius: 8px;
      cursor: pointer;
      position: relative;
      overflow: hidden !important;
      /* height: 100%; */
      width: 100% !important;
      transition: all 0.3s ease 0s;

      .rounded-image {
        overflow: hidden !important;
        transition: transform 0.5s ease-in-out 0.2s;
        img {
          transition: all 0.35s ease-in;
        }
      }
      .h5-heading {
        h5 {
          line-height: 24px !important;
          padding: 0 !important;
          font-size: 17px !important;
          font-weight: 550 !important;
          width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .group-name {
        color: #585981;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 10px !important;
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .apartment,
      .location {
        font-size: 12px;
        color: #8f90a6;
        font-weight: 400;
        line-height: 19px;
      }
      .price {
        font-size: 14px;
        font-weight: 500;
        color: #2b2b2b;
        text-align: center;
        line-height: 22px;
      }
      .view-detail-btn {
        background-color: #7a1ea3;
        color: #fff;
        width: 120px !important;
        font-size: 14px;
        transition: all 0.3s ease-in-out;
        font-weight: 400;
        position: absolute;
        display: block;
        top: 420px;
        text-align: center;
        padding: 8px 12px;
        border-radius: 25px;
      }
    }
    .popularOwner-slider-card:hover {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 12px 0px;
      transition: all 0.3s ease 0s;
    }
    .popularOwner-slider-card:hover .view-detail-btn {
      transition: all 0.3s ease-in-out 0.1s;
      top: 310px;
      right: 4%;
    }
    .popularOwner-slider-card:hover .rounded-image img {
      transform: scale(1.1);
    }
  }
  @media (min-width: 768px) and (max-width: 912px) {
    .p-carousel-items-container {
      display: flex;
      flex-direction: row;
      gap: 8px !important;
    }
    .slider-one-card {
      width: 65% !important;
    }
    .testomonial-slider-card {
      width: 97% !important;
      height: 253px;
    }
    .top-agencies-slider-card {
      width: 97% !important;
    }
    .top-agents-slider-card {
      width: 95% !important;
    }
    .spotlight-container-slider-card {
      display: flex;
      font-family: "Poppins", sans-serif !important;
      flex-wrap: wrap;
      padding: 0px !important;
      border-radius: 20px;
      background-image: linear-gradient(
        to left bottom,
        rgba(255, 234, 203, 1),
        rgba(255, 232, 253, 1)
      );

      .left-spotlight {
        /* width: 25%; */
        display: none !important;
        background: transparent;
      }
      .right-spotlight {
        width: 100% !important;
        height: 350px;
        border-radius: 8px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        padding: 30px 16px;
        display: flex;

        align-items: end;

        .spotlight-image-content {
          visibility: visible !important;
          color: #fff !important;
          width: 100%;
          display: flex;
          align-items: end;
          justify-content: space-between;
          brand-image-name {
            h6 {
              font-size: 17px !important;
              font-weight: 550 !important;
              line-height: 23px;
              width: 250px !important;
            }
          }
          .location-data {
            font-size: 17px;
            color: #cbcbd6 !important;
          }
          .apartment {
            font-size: 16px;
            font-weight: 400;
          }
          .price-value {
            font-size: 18px !important;
            font-weight: 500 !important;
            color: #fff;
          }
        }
        .primary-btn {
          display: inline-block !important;
          text-align: center;
          width: 160px !important;
          padding: 10px 10px !important;
          font-size: 17px;
          font-weight: 400;
          border-radius: 12px !important;
        }

        .carousel-indicators {
          position: absolute;
          bottom: -10px !important; /* Adjust the position as needed */
          left: 41%;

          display: block !important;
          transform: translateX(-50%);
        }

        .indicator {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #888; /* Default color */
          border-radius: 50%;
          margin: 0 5px; /* Adjust spacing between indicators */
        }

        .indicator.active {
          background-color: #fff; /* Active color */
        }
      }
    }

    .features-collection-slider-card {
      width: 96% !important;
      .rounded-image-div {
        height: 220px !important;
      }
    }

    .category-slider-card {
      width: 100%;
      .gray-normal-btn {
        button {
          background: none;
          border: none;
          outline: none;
          color: #8f90a6;
          font-size: 16px;
          font-weight: 300;
          gap: 16px;
        }
      }
    }
    .primeProject-slider-card {
      width: 96% !important;
      .rounded-image {
        img {
          margin-bottom: 15px;
        }
      }
      .h5-heading {
        h5 {
          font-size: 16px;
        }
      }
      .group-name {
        font-size: 14px !important;
      }
      .apartment,
      .location {
        font-size: 12px;
      }
      .price {
        font-size: 14px;
      }
    }
    .Prestigious-slider-card {
      width: 100% !important;
      .rounded-image-div {
        width: 100%;
        height: 300px;

        .content-div {
          display: flex !important;
          width: 100%;
          justify-content: space-between !important;
          flex-wrap: wrap;
          position: relative;
          z-index: 1; /* Ensure content is above the overlay */
          color: #ffffff;

          .h5-heading {
            h5 {
              line-height: 16px !important;
              padding: 2px 0;
            }
          }
          .group-name {
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 22px !important;
          }
          .apartment,
          .location {
            font-size: 12px;

            font-weight: 400;
            line-height: 19px;
          }
          .price {
            font-size: 14px !important;
            font-weight: 500;

            line-height: 22px;
          }
        }
      }
    }
    .top-selections-slider-card {
      width: 100% !important;
      .rounded-image-div {
        position: relative;
        width: 100%;
        height: 200px;
        border-radius: 13px;
        overflow: hidden;
        color: #fff !important;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 16px;
        margin-bottom: 20px;

        .content-div {
          display: flex !important;

          flex-direction: column;
          height: 100%;
          justify-content: space-between !important;
          position: relative;
          font-size: 14px !important;
          z-index: 1;
          color: #ffffff;
          .rera-tag {
            align-self: flex-start;
            background-color: #06afb0;
            display: block !important;
            padding: 6px 10px;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }

      .bottom-content-div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .h5-heading {
          h5 {
            line-height: 24px !important;
            font-size: 16px !important;
            padding: 0 !important;
            margin: 0;
            font-weight: 700 !important;
          }
        }

        .apartment,
        .location {
          font-size: 12px;
          color: #8f90a6;
          font-weight: 400;
          line-height: 19px;
        }
        .price {
          font-size: 14px;
          font-weight: 500;

          line-height: 22px;
        }
      }
    }
    .premier-property-slider-card {
      width: 100% !important;
    }
    .Hot-Picks-slider-card {
      width: 92% !important;
      display: flex;
      /* justify-content: space-between; */
      gap: 32px !important;
     
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    .spotlight-container-slider-card {
      display: flex;
      font-family: "Poppins", sans-serif !important;
      flex-wrap: wrap;
      padding: 0px !important;
      border-radius: 20px;
      background-image: linear-gradient(
        to left bottom,
        rgba(255, 234, 203, 1),
        rgba(255, 232, 253, 1)
      );

      .left-spotlight {
        /* width: 25%; */
        display: none !important;
      }
      .right-spotlight {
        width: 100% !important;
        height: 350px;
        border-radius: 8px;
        background-size: cover;
        position: relative;
        background-repeat: no-repeat;
        cursor: pointer;
        padding: 30px 16px;
        display: flex;

        align-items: end;

        .spotlight-image-content {
          visibility: visible !important;
          color: #fff !important;
          width: 100%;
          display: flex;
          align-items: end;
          justify-content: space-between;
          brand-image-name {
            h6 {
              font-size: 15px !important;
              font-weight: 550 !important;
              line-height: 23px;
              width: 190px !important;
            }
          }
          .location-data {
            font-size: 14px;
            color: #cbcbd6 !important;
          }
          .apartment {
            font-size: 14px;
            font-weight: 400;
          }
          .price-value {
            font-size: 15px !important;
            font-weight: 500 !important;
            color: #fff;
          }
        }
        .primary-btn {
          display: inline-block !important;
          text-align: center;
          width: 150px !important;
          padding: 10px 10px !important;
          font-size: 16px;
          font-weight: 400;
          border-radius: 12px !important;
        }

        .carousel-indicators {
          position: absolute;
          bottom: -10px !important; /* Adjust the position as needed */
          left: 41%;

          display: block !important;
          transform: translateX(-50%);
        }

        .indicator {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #888;
          border-radius: 50%;
          margin: 0 5px;
        }

        .indicator.active {
          background-color: #fff;
        }
      }
    }
    .popularOwner-slider-card {
      /* margin: 0 30px 0 0; */
      font-family: "Poppins", sans-serif !important;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden !important;
      /* height: 100%; */
      position: relative;
      width: 95% !important;
      transition: all 0.3s ease 0s;

      .rounded-image {
        overflow: hidden !important;
        transition: transform 0.5s ease-in-out 0.2s;
        img {
          transition: all 0.35s ease-in;
        }
      }
      .h5-heading {
        h5 {
          line-height: 24px !important;
          padding: 0 !important;
          font-size: 18px !important;
          font-weight: 550 !important;
          width: 160px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .group-name {
        color: #585981;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 10px !important;
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .apartment,
      .location {
        font-size: 12px;
        color: #8f90a6;
        font-weight: 400;
        line-height: 19px;
      }
      .price {
        font-size: 14px;
        font-weight: 500;
        color: #2b2b2b;
        text-align: center;
        line-height: 22px;
      }
      .view-detail-btn {
        background-color: #7a1ea3;
        color: #fff;
        width: 120px;
        font-size: 12px;
        transition: all 0.3s ease-in-out;
        font-weight: 400;
        position: absolute;
        display: block;
        top: 420px;
        text-align: center;
        padding: 8px 12px;
        border-radius: 25px;
      }
    }
    .popularOwner-slider-card:hover {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 12px 0px;
      transition: all 0.3s ease 0s;
    }
    .popularOwner-slider-card:hover .view-detail-btn {
      transition: all 0.3s ease-in-out 0.1s;
      top: 360px !important;
      right: 4% !important;
    }
    .popularOwner-slider-card:hover .rounded-image img {
      transform: scale(1.1);
    }
  }
}
.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: orange;
  width: 10px;
  height: 10px;
}
.p-rating .p-rating-item.p-focus {
  box-shadow: none;
}
.p-rating {
  gap: 4px;
}
