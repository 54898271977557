.sidebar-menu-body {
  background-color: #fff;
  height: 100%;
overflow-y: scroll !important;
  padding: 16px 12px;
  color: #2b2b2b !important;
  font-family: "Poppins", sans-serif !important;
  .login-btn {
    color: #7a1ea3;
    font-size: 15px !important;
    font-weight: 550 !important;
    display: flex;
    text-transform: uppercase;
    gap: 5px;
    font-family: "Poppins", sans-serif !important;
    align-items: center;
  }
  .accordian-header-menu {
    padding: 9px 0;
  }
  .accordian-header-menu:hover {
    color: #7a1ea3 !important;
  }
  .color-down-content {
    background-color: #e8deed81;
    padding: 15px 16px;
    border-radius: 4px;
  }
  .sidebar-drop-content {
    font-size: 13px !important;
    padding: 3px 0;
    color: #2b2b2b !important;
    font-weight: 500 !important;
    font-family: "Poppins", sans-serif !important;
  }

  .p-accordion-header a {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    font-family: "Poppins", sans-serif !important;
  }
  .p-accordion-header a:hover{
    color: #7a1ea3 !important;
  }
  .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background: none;
    border: none;
    color: #2b2b2b;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0;
    border: none !important;
    font-size: 15px !important;
    color: #2b2b2b;
    background: transparent;
    font-weight: 600 !important;

    box-shadow: none;
    transition: box-shadow 0.2s;
  }

  .p-accordion .p-accordion-content {
    padding: o;
    border: none;
    background: transparent;
    color: #2b2b2b;
  }

  .top-nav-content{
    .nav-data{
        color: #2b2b2b;
        font-size: 15px !important;
        font-weight: 600 !important;
        padding: 5px 0;
        cursor: pointer;
    }
  }
  .contact-heading{
    font-size: 17px !important;
    font-weight: 600 !important;
    color: #2b2b2b;
  }
  .contact-detail{
    display: flex;
    align-items: center;
    gap: 8px;
    p{
        color: gray;
        font-size: 12px;
        padding: 0;
        margin: 0;
    }
    .number{
        font-size: 14px;
        font-weight: 600;
        color: #2b2b2b;
    }
  }
}
